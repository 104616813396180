import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { WarehouseViewComponent } from './view/view.component';
import { WarehouseListComponent } from './list/list.component';
import { WarehouseNewComponent } from './new/new.component';
import { WarehouseAddStockComponent } from './add-stock/add-stock.component';
import { WarehouseRemoveStockComponent } from './remove-stock/remove-stock.component';
import { WarehouseTransferStockComponent } from './transfer-stock/transfer-stock.component';
import { WarehouseStockLogsComponent } from './stock-logs/stock-logs.component';
import { CreateStockComponent } from './create-stock/create-stock.component';
import { WarehouseItemModifyComponent } from './modify-item/modify.component';

@NgModule({
  declarations: [
    WarehouseListComponent,
    WarehouseViewComponent,
    WarehouseNewComponent,
    WarehouseAddStockComponent,
    WarehouseRemoveStockComponent,
    WarehouseTransferStockComponent,
    WarehouseStockLogsComponent,
    CreateStockComponent,
    WarehouseItemModifyComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class WarehousesModule {}
