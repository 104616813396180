<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Name
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="name" placeholder="Name" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Address
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="address" placeholder="Address" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Postal code
      </div>
      <div nz-col nzSpan="12">
        <input
          nz-input
          formControlName="postalCode"
          placeholder="Postal code"
        />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Region
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="region" placeholder="Region" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Geolocation LAT
      </div>
      <div nz-col nzSpan="12">
        <input
          nz-input
          formControlName="geoLocationLat"
          placeholder="Geolocation lat"
        />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Geolocation LONG
      </div>
      <div nz-col nzSpan="12">
        <input
          nz-input
          formControlName="geoLocationLong"
          placeholder="Geolocation long"
        />
      </div>
    </div>

    <!-- <div nz-row nzGutter="16" style="margin-bottom: 10px">
    <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
      Is enabled
    </div>
    <div nz-col nzSpan="12">
      <nz-switch [(ngModel)]="switchValue"></nz-switch>
    </div>
  </div> -->

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">
        {{ item ? "Update" : "Create" }}
      </button>
    </div>
  </form>
</nz-spin>
