<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Users">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="newUser()">
        Add new user
      </button>
    </nz-page-header-extra>
  </nz-page-header>

  <nz-table #basicTable nzSize="small" [nzData]="users" style="min-width: 100%" [nzScroll]="{ x: '800px' }">
    <thead>
      <tr>
        <th>Username</th>
        <th>Status</th>
        <th>Roles</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of basicTable.data">
        <td>{{ item.username }}</td>
        <td>
          <nz-tag nzColor="success" *ngIf="item.isActive === true">Active</nz-tag>
          <nz-tag nzColor="error" *ngIf="item.isActive === false">Disabled</nz-tag>
        </td>
        <td>
          <nz-tag *ngFor="let role of item.roles">{{ role.value }}</nz-tag>
        </td>
        <td>
          <button nz-button nzShape="round" nzSize="small" style="margin-right: 3px" (click)="editItem(item)">
            <i class="fas fa-cogs"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>