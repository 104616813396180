<div nz-row>
  <div nz-col [nzLg]="8"></div>
  <div nz-col style="background-color: white; margin-right:-4%;" [nzLg]="4" id="qr-to-print">

    <qrcode [qrdata]="qrCodeValue" [width]="width" errorCorrectionLevel="H" elementType="url" (click)="printQrCode()" cssClass="bshadow"></qrcode>


  </div>
  <div nz-col [nzLg]="4" style="padding-top: 10px; font-size: 16px; background-color: white;" *ngIf="text"
    [innerHtml]="text"></div>
  <div nz-col [nzLg]="8"></div>

</div>
