<div nz-row nzJustify="center">
  <div nz-col nzXs="23" nzSm="23" nzLg="16" nzXl="10" style="text-align: center; margin-bottom: 50px">
    <div style="height: 150px; margin-top: 10%; margin-bottom: 30px">
      <img src="../../../assets/Leap.png" style="width: 70%; height: 150px" />
    </div>

    <div *ngIf="list === undefined">
      <div style="margin-bottom: 10px">
        <nz-input-group nzPrefixIcon="mail">
          <input type="text" nz-input [(ngModel)]="email" placeholder="Email" />
        </nz-input-group>
      </div>
      <div style="margin-bottom: 10px">
        <nz-input-group nzPrefixIcon="lock">
          <input type="password" nz-input [(ngModel)]="password" placeholder="Password" />
        </nz-input-group>
      </div>
      <div style="text-align: center;">
        <button nz-button [nzType]="'primary'" (click)="login()" [nzLoading]="isLoading">
          Log in
        </button>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24" *ngFor="let item of list" style="margin-bottom: 5px;">
        <nz-card nzHoverable style="background-color: rgba(46, 119, 201, 0.3);" (click)="setInstance(item)">
          <b>{{ item.instance.name }}</b>
        </nz-card>
      </div>
    </div>

  </div>
</div>