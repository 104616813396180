import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.less'],
})
export class QrGeneratorComponent implements OnInit {
  @Input() text;
  @Input() qrCodeValue;

  @Input() width = 150;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (!this.qrCodeValue) {
      this.activatedRoute.queryParams.subscribe((r) => {
        this.text = r.text;
        this.qrCodeValue = r.value;
      });
    }
  }
  printQrCode() {
    window.print();
  }
}
