<nz-spin [nzSpinning]="isLoading">
  <nz-page-header (nzBack)="toList()" nzBackIcon nzTitle="Delivery {{ id }}">
    <nz-page-header-extra>
      <button *ngIf="item?.status === 'New'" nz-button nzShape="round" (click)="beginDelivery()">
        Start delivery
      </button>
      <button *ngIf="item?.status === 'InTransit'" nz-button nzShape="round" (click)="finishDelivery()">
        Finish delivery
      </button>
      <button nz-button nzType="primary" style="margin-left: 5px;" nzShape="round" (click)="exportToPDF()">Export to
        PDF</button>
    </nz-page-header-extra>
    <nz-page-header-content>
      <nz-descriptions nzSize="small" [nzColumn]="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }">
        <nz-descriptions-item nzTitle="Status" [nzSpan]="1">
          <nz-tag [nzColor]="getStatusColor(item.status)">
            {{ item.status }}
          </nz-tag> 
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="item?.createdOn" nzTitle="Created On" [nzSpan]="1">
          {{ formatDate(item?.createdOn) }}
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="item?.startDateTime" nzTitle="Start Date" [nzSpan]="1">
          {{ formatDate(item?.startDateTime) }}
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="item?.finishDateTime" nzTitle="Finish Date" [nzSpan]="1">
          {{ formatDate(item?.finishDateTime) }}
        </nz-descriptions-item>
      </nz-descriptions>
    </nz-page-header-content>
  </nz-page-header>

  <nz-collapse>
    <nz-collapse-panel *ngFor="let dispatch of dispatches" [nzHeader]="dispatch.type" [nzActive]="true">
      <nz-collapse>
        <nz-collapse-panel *ngFor="let entry of dispatch.entries"
          nzHeader="Dispatches included: {{ entry.dispatchRequestIds.length }}"
          [nzActive]="canShowQrCodePart(entry.statuses)" [nzExtra]="statuses">
          <ng-template #statuses>
            <nz-tag *ngFor="let status of entry.statuses" [nzColor]="getStatusColor(status)">{{ status }}</nz-tag>
          </ng-template>
          <div>
            <div nz-row nzGutter="16">
              <div nz-col nzXs="24" nzSm="24" nzMd="16">
                <div *ngIf="dispatch.type === 'transfer'">
                  <div>
                    Source warehouse:
                    <a [routerLink]="['/warehouse', entry.sourceWarehouse.id]">{{ entry.sourceWarehouse.name }}</a>
                  </div>
                  <div>
                    Target warehouse:
                    <a [routerLink]="['/warehouse', entry.targetWarehouse.id]">{{ entry.targetWarehouse.name }}</a>
                  </div>
                </div>
                <div *ngIf="dispatch.type === 'dispatch'">
                  <div>
                    Source warehouse:
                    <a [routerLink]="['/warehouse', entry.sourceWarehouse.id]">{{ entry.sourceWarehouse.name }}</a>
                  </div>
                  <div>
                    Client:
                    <a [routerLink]="['/client', entry.client.id]">{{
                      entry.client.name
                      }}</a>
                  </div>
                  <div>
                    Location:
                    <a [routerLink]="['/location', entry.clientLocation.id]">{{
                      entry.clientLocation.name
                      }}</a>
                  </div>
                </div>
                <div *ngIf="dispatch.type === 'return'">
                  <div>
                    Client:
                    <a [routerLink]="['/client', entry.client.id]">{{
                      entry.client.name
                      }}</a>
                  </div>
                  <div>
                    Location:
                    <a [routerLink]="['/location', entry.clientLocation.id]">{{
                      entry.clientLocation.name
                      }}</a>
                  </div>
                  <div>
                    Target warehouse:
                    <a [routerLink]="['/warehouse', entry.targetWarehouse.id]">{{ entry.targetWarehouse.name }}</a>
                  </div>
                </div>
              </div>
              <div nz-col nzXs="24" nzSm="24" nzMd="8" style="text-align: center;">
                <app-qr-generator qrCodeValue="{{ getQrCodeValue(entry.dispatchRequestIds) }}"
                  width="{{ getQrCodeSize(entry.dispatchRequestIds) }}"></app-qr-generator>
              </div>
            </div>
          </div>

          <h4>Items</h4>
          <nz-list nzItemLayout="horizontal" nzBordered style="margin-bottom: 5px">
            <nz-list-item *ngFor="let i of entry.items">
              <ng-container>
                <nz-list-item-meta>
                  <nz-list-item-meta-title>
                    {{ i.item.name }} {{ i.isUsed ? "(Used)" : "" }}
                  </nz-list-item-meta-title>
                </nz-list-item-meta>
                Amount: {{ i.amount }}
                <label nz-checkbox style="margin-left: 7px;" [nzChecked]="selectedItem === i"></label>
              </ng-container>
            </nz-list-item>
          </nz-list>

        </nz-collapse-panel>
      </nz-collapse>
    </nz-collapse-panel>
  </nz-collapse>

  <hr />
  <h3>Map</h3>
  <app-common-map *ngIf="isLoading === false" [locations]="routeOrganizer.targetClientLocations"></app-common-map>
</nz-spin>