<nz-table *ngIf="history" #itemHistoryTable nzSize="small" [nzData]="history" style="min-width: 100%;">
    <thead>
        <tr>
            <th>Created on</th>
            <th>Price</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let i of itemHistoryTable.data">
            <td>
                {{ i.CreatedOn }}
            </td>
            <td>{{ i.Price }}</td>
        </tr>
    </tbody>
</nz-table>