import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { ConfigsItemsListComponent } from './items-categories/list/list.component';
import { ConfigsItemsKitsListComponent } from './items-kits/list/list.component';

export const routes: Routes = [
  {
    path: 'configs/items/categories',
    component: ConfigsItemsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'configs/items/kits',
    component: ConfigsItemsKitsListComponent,
    canActivate: [AuthGuard],
  },
];
