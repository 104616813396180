import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './home/dashboard/dashboard.component';

import { routes as warehouseRoutes } from './warehouses/warehouses-routing.module';
import { routes as itemsRoutes } from './items/items-routing.module';
import { routes as configsRoutes } from './configs/configs-routing.module';
import { routes as clientsRoutes } from './clients/clients-routing.module';
import { routes as clientsLocationsRoutes } from './clients-locations/clients-locations-routing.module';
import { routes as dispatchRequestsRoutes } from './dispatch-requests/dispatch-requests-routing.module';
import { routes as deliveriesRoutes } from './deliveries/delivieries-routing.module';
import { routes as suppliersRoutes } from './suppliers/suppliers-routing.module';
import { UsersListComponent } from './users/list/list.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/login/auth-guard';
import { QrScannerComponent } from './common/qr-scanner/qr-scanner.component';
import { LegalComponent } from './common/legal/legal.component';
import { QrGeneratorComponent } from './common/qr-generator/qr-generator.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  ...warehouseRoutes,
  ...itemsRoutes,
  ...configsRoutes,
  ...clientsRoutes,
  ...clientsLocationsRoutes,
  ...dispatchRequestsRoutes,
  ...deliveriesRoutes,
  ...suppliersRoutes,
  {
    path: 'users',
    component: UsersListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'qr-scanner',
    component: QrScannerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'postlogin/:uid/:jwt',
    component: LoginComponent,
  },
  {
    path: 'legal',
    component: LegalComponent,
  },
  {
    path: 'qr/generator',
    component: QrGeneratorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
