<div nz-row style="text-align: center">
  <div nz-col [nzSpan]="8">Field</div>
  <div nz-col [nzSpan]="8">Search</div>
  <!-- <div nz-col [nzSpan]="8">Order by</div> -->
</div>
<!-- <div
  nz-row
  *ngFor="let field of filterableColumns"
  style="text-align: center; margin-top: 15px"
>
  <div nz-col [nzSpan]="8">{{ field.label }}</div>
  <div nz-col [nzSpan]="8">
    <input
      nz-input
      placeholder="Search value"
      style="width: 80%"
      [(ngModel)]="field.searchValue"
    />
  </div>
</div> -->
