<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Locations">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="filterVisiblebtn()">
        <i class="fas fa-search"></i>
      </button>
    </nz-page-header-extra>
    <nz-page-header-content>
      <div nz-row *ngIf="filterVisible">
        <div nz-row *ngFor="let field of filterModalFields" style="text-align: center; margin-top: 15px">
        </div>
        <div nz-col nzSpan="12" *ngFor="let field of filterModalFields" style="text-align: center; margin-top: 5px; ">
          <input nz-input placeholder="Search for {{ field.name  }}" [(ngModel)]="field.searchValue" />
        </div>
        <div nz-col nzSpan="24" style=" margin-top: 15px">
          <button nz-button nzType="primary" style="float: right;" nzShape="round" (click)="filterData()">
            Search <i style="margin-left: 5px;" class="fas fa-search"></i>
          </button>          
        </div>
      </div>
    </nz-page-header-content>
  </nz-page-header>

  <div nz-row nzGutter="12">
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="8" *ngFor="let item of displayItems">
      <nz-card nzSize="small" [nzTitle]="item.name" style="margin-bottom: 12px" nzHoverable
        [nzActions]="[actionSetting, actionOpen]" [nzExtra]="extra">
        <ng-template #extra>
          <nz-tag [nzColor]="'volcano'" *ngIf="item.isActive === false">Inactive</nz-tag>
          <nz-tag [nzColor]="'green'" *ngIf="item.isActive === true">Active</nz-tag>
        </ng-template>
        <div>Street: {{ item.street }}</div>
        <div>Number: {{ item.number }}</div>
        <div>Postal code: {{ item.postalCode }}</div>
        <div *ngIf="!clientId">Client: {{ item?.client?.name }}</div>
        <h4>Stock value: €{{ item.stockValue }}</h4>

        <ng-template #actionSetting>
          <div nz-tooltip nzTooltipTitle="Settings" (click)="edit(item)">
            <i class="fas fa-cogs"></i>
          </div>
        </ng-template>
        <ng-template #actionOpen>
          <div nz-tooltip nzTooltipTitle="Open" [routerLink]="['/location', item.id]">
            <i class="far fa-eye"></i>
          </div>
        </ng-template>
      </nz-card>
    </div>
  </div>
</nz-spin>

<!-- <nz-modal [(nzVisible)]="filterModalVisible" nzTitle="Filters/searches" (nzOnCancel)="filterModalVisible = false"
  (nzOnOk)="filterData()" nzWidth="80%">
  <div nz-row style="text-align: center">
    <div nz-col [nzSpan]="8">Field</div>
    <div nz-col [nzSpan]="8">Search</div>
  </div>
  <div nz-row *ngFor="let field of filterModalFields" style="text-align: center; margin-top: 15px">
    <div nz-col [nzSpan]="8">{{ field.name }}</div>
    <div nz-col [nzSpan]="8">
      <input nz-input placeholder="Search value" style="width: 80%" [(ngModel)]="field.searchValue" />
    </div>
  </div>
</nz-modal> -->