
  <nz-page-header  nzTitle="Dashboard">
    <nz-page-header-extra>
    
  
    </nz-page-header-extra>
  </nz-page-header>
<div nz-row>
  <div nz-col nzXs="24" nzSm="24" nzMd="12">
    <nz-card style="width: 100%" nzTitle="Warehouse low stock" nzSize="small">
      <div
        nz-row
        nzGutter="12"
        *ngFor="let warehouse of warehouseLowStocks"
        style="margin-bottom: 5px"
      >
        <div nz-col nzSpan="12" style="text-align: right">
          {{ warehouse.name }}:
        </div>
        <div nz-col nzSpan="12">
          <span *ngIf="warehouse.lowStock.length === 0"
            ><nz-tag [nzColor]="'green'">OK</nz-tag></span
          >
          <span
            *ngIf="warehouse.lowStock.length > 0"
            (click)="lowStockWarehouse = warehouse"
            ><nz-tag [nzColor]="'orange'"
              >Missing stock on {{ warehouse.lowStock.length }} items</nz-tag
            ></span
          >
        </div>
      </div>
    </nz-card>
  </div>
</div>

<nz-modal
  [(nzVisible)]="lowStockWarehouse"
  nzTitle="Low stock in warehouse {{ lowStockWarehouse?.name }}"
  [nzFooter]="null"
  nzWidth="90%"
  (nzOnCancel)="lowStockWarehouse = undefined"
>
<ng-container *nzModalContent>
  <nz-table
    #lowStockTable
    [nzData]="lowStockWarehouse?.lowStock"
    nzSize="small"
  >
    <thead>
      <tr>
        <th>Item name</th>
        <th>Amount / Min</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of lowStockTable.data">
        <td>{{ data.item.name }}</td>
        <td>{{ data.amount }} / {{ data.lowStockAmount }}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-container>
</nz-modal>
