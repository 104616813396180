<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Amount
      </div>
      <div nz-col nzSpan="12">
        <input
          type="number"
          nz-input
          formControlName="amount"
          placeholder="Amount"
        />
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">Add</button>
    </div>
  </form>
</nz-spin>
