import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-warehouse-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.less'],
})
export class WarehouseAddStockComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });
  @Input() warehouseItemId;

  form: FormGroup;

  constructor(private apollo: Apollo, private modalRef: NzModalRef, private messageService: NzMessageService) {
    this.form = new FormGroup({
      action: new FormControl('+'),
      warehouseItemId: new FormControl(null, [Validators.required]),
      amount: new FormControl(1, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.warehouseItemId = this.nzModalData.warehouseItemId;
    this.form.get(`warehouseItemId`).setValue(this.warehouseItemId);
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: ModifyWarehouseItemStockCommand) {
            modifyWarehouseItemStock(data: $data)
          }
        `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.messageService.success(`New stock has been added successfully.`)
        this.modalRef.close('ok');
      });
  }
}
