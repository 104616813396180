import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-dispatch-requests-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class DispatchRequestsViewComponent implements OnInit {
  isLoading;

  @Input() id;

  item;
  isVisible = false;
  items = [];
  selectedItems = [];
  form: FormGroup;
  newItem;

  canModify = false;

  constructor(
    private router: Router,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private messageService: NzMessageService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.clearNewItem();
    this.activatedRoute.params.subscribe((r) => {
      this.id = r['id'];
      this.load();
    });
    this.form = this.formBuilder.group({
      itemId: [null, Validators.required],
      quantity: [null, Validators.required]
    });
  }

  toList = () => this.router.navigateByUrl(`/dispatch-requests`);

  load() {
    this.isLoading = true;
    this.apollo.use('common').query({
      fetchPolicy: 'no-cache',
      query: gql`
        {
          dispatchRequest(id: "${this.id}") {
            status
            type
            createdOn
            startDateTime
            finishDateTime
            warehouse {
              id
              name
              items {
                id
                amount
                isUsed
                item {
                  id
                  name
                }
              }
            }
            targetWarehouse {
              id
              name
            }
            client {
              name
            }
            clientLocation {
              name
            }
            items {
              amount
              isUsed
              item {
                id
                name
                brand
                serialNumber
                referenceNumber
              }
            }
          }
        }
      `,
    }).subscribe((r: any) => {
      this.item = r.data.dispatchRequest;
      this.selectedItems = this.item.items;
      this.items = this.item.warehouse.items.filter(
        (x) => !this.selectedItems.map((y) => y.item.id).includes(x.item.id)
      );

      console.log('Fetched items:', this.item.warehouse.items);
      console.log('Selected items:', this.selectedItems);
      console.log('Filtered items:', this.items);

      this.canModify = this.item.status === 'New';
      this.isLoading = false;
    });
  }

  removeItem(warehouseItemId: string) {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: RemoveItemFromDispatchRequestCommand) {
            removeItemFromDispatchRequest(data: $data)
          }
        `,
        variables: {
          data: {
            dispatchRequestId: this.id,
            warehouseItemId: warehouseItemId, 
          },
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.messageService.success(`Item removed from dispatch request.`);
        this.load();
      });
  }
  
  
  
  addItem() {
    this.isLoading = true;
    console.log('New item:', this.newItem);
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: AddItemToDispatchRequestCommand) {
            addItemToDispatchRequest(data: $data)
          }
        `,
        variables: {
          data: {
            dispatchRequestId: this.id,
            warehouseItemId: this.newItem.itemId, 
            amount: this.newItem.amount,
          },
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        var item = this.items.filter(
          (x) => x.id === this.newItem.itemId 
        )[0];
        this.messageService.success(
          `Item ${item.item.name} added to dispatch request.`
        );
        console.log('Updated selectedItems:', this.selectedItems);
        this.clearNewItem();
        this.load();
      });
  }

  isVisiblebtn(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    console.log('Button cancel clicked!');
    this.isVisible = false;
  }

  isNotSelected(itemId: string): boolean {
    return this.selectedItems.find((item) => item.item.id === itemId) === undefined;
  }

  clearNewItem = () =>
    (this.newItem = {
      itemId: null, 
      amount: 1,
    });
    formatDate(dateTimeString): string {
      const date = new Date(dateTimeString);
      return format(date, 'dd/MM/yyyy');
    }
    getStatusColor(status: string): string {
      switch (status) {
        case 'Completed':
          return 'green';
        case 'AssignedToDelivery':
          return 'orange';
        case 'InTransit':
          return 'purple';
        case 'New':
          return 'blue';
        default:
          return 'default';
      }
    }
}
