import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ClientsModifyComponent } from '../modify/modify.component';

@Component({
  selector: 'app-clients-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class ClientsListComponent implements OnInit {
  isLoading;

  items = [];
  displayItems = [];
  filterVisible = false;

  filterModalFields = [
    {
      name: 'Name',
      dataIndex: 'name',
      searchValue: undefined,
      // orderBy: undefined,
    },
  ];

  constructor(
    private modalService: NzModalService,
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((r) => {
      this.filterModalFields.forEach((field) => {
        if (r[field.dataIndex]) field.searchValue = r[field.dataIndex];
      });
      this.load();
    });
  }

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            clients {
              id
              name
              isActive
              locations {
                id
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.items = r.data.clients;
        this.displayItems = this.items;
        this.isLoading = false;
        this.filterData();
      });
  }
  filterVisiblebtn(){
    this.filterVisible = true;
   }

  new() {
    this.modalService
      .create({
        nzTitle: 'Add new client',
        nzFooter: null,
        nzContent: ClientsModifyComponent,
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  edit(item) {
    this.modalService
      .create({
        nzTitle: 'Edit client',
        nzFooter: null,
        nzContent: ClientsModifyComponent,
        nzData: {
          item: item
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  filterData() {
    var queryParams = {};
    this.displayItems = this.items;

    this.filterModalFields.forEach((field) => {
      if (field.searchValue) {
        this.displayItems = this.displayItems.filter((x) =>
          x[field.dataIndex]
            .toLowerCase()
            .includes(field.searchValue.toLowerCase())
        );
        queryParams[field.dataIndex] = field.searchValue;
      } else {
        queryParams[field.dataIndex] = null;
      }
    });

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

}
