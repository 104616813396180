import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { WarehouseAddStockComponent } from '../add-stock/add-stock.component';
import { CreateStockComponent } from '../create-stock/create-stock.component';
import { WarehouseRemoveStockComponent } from '../remove-stock/remove-stock.component';
import { WarehouseStockLogsComponent } from '../stock-logs/stock-logs.component';
import { WarehouseTransferStockComponent } from '../transfer-stock/transfer-stock.component';

import { orderBy } from 'lodash';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { WarehouseItemModifyComponent } from '../modify-item/modify.component';
import { WarehouseService } from '../warehouse.service';

@Component({
  selector: 'app-warehouse-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class WarehouseViewComponent implements OnInit {
  isLoading;

  id;
  item;
  isInitialized = false;
  stockFilterItemType = 'All';
  pageSize = '20';

  displayItems = [];
  itemsColumns = [
    { label: 'Name', search: true, isVisible: false, searchValue: '' },
    {
      label: 'Serial number',
      search: true,
      isVisible: false,
      searchValue: '',
      filterItemType: ['Asset', 'All'],
    },
    { label: 'Condition', search: true, isVisible: false, searchValue: '' },
    { label: 'Reference number', search: true, isVisible: false, searchValue: '' },
    { label: 'Brand', search: true, isVisible: false, searchValue: '' },
    { label: 'Amount', filterItemType: ['Consumable', 'Equipment', 'All'] },
    { label: '' },
  ];

  constructor(
    private location: Location,
    private modalService: NzModalService,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private warehouseService: WarehouseService
  ) { }

  ngOnInit(): void {


    forkJoin({
      params: new Promise((resolve) =>
        this.activatedRoute.params.subscribe((r) => resolve(r))
      ),
      queryParams: new Promise((resolve) =>
        this.activatedRoute.queryParams.subscribe((r) => resolve(r))
      ),
    }).subscribe((r) => {
      console.log('r', r);
      this.id = r.params['id'];
      this.stockFilterItemType = !r.queryParams['itemType']
        ? 'All'
        : r.queryParams['itemType'];
      this.load();

      if (!this.warehouseService.transferWarehouseId || this.warehouseService.transferWarehouseId != this.id) {
        this.warehouseService.transferWarehouseId = this.id;
        this.warehouseService.list = [];
      }

    });
  }
  onStockFilterChange() {
    if (this.stockFilterItemType === 'All') {
      this.displayItems = orderBy(
        this.item.items,
        [(item) => item.item.type !== 'Consumable', (item) => item.item.type !== 'Equipment']
      );
      this.itemsColumns.forEach(column => {
        column.isVisible = true;
      });
    } else {
      this.displayItems = this.item.items.filter(
        x => x.item.type === this.stockFilterItemType
      );
      this.itemsColumns.forEach(column => {
        if (column.filterItemType) {
          column.isVisible = column.filterItemType.includes(
            this.stockFilterItemType
          );
        } else {
          column.isVisible = false;
        }
      });
    }
  }



  load() {
  this.router.navigate(['warehouse', this.id], {
    queryParams: { itemType: this.stockFilterItemType },
    queryParamsHandling: 'merge',
  });
  this.isLoading = true;
  this.apollo
    .use(`common`)
    .query({
      fetchPolicy: 'no-cache',
      query: gql`{ warehouse(id:"${this.id}"){ id name items { id amount isUsed lowStockAmount item { id serialNumber name brand referenceNumber type } } }}`,
    })
    .subscribe((r: any) => {
      this.item = r.data.warehouse;

      if (this.stockFilterItemType === 'All') {
        this.displayItems = orderBy(
          this.item.items,
          [(item) => item.item.type !== 'Consumable', (item) => item.item.type !== 'Equipment']
        );
      } else {
        this.displayItems = this.item.items.filter(
          (x) => x.item.type === this.stockFilterItemType
        );
      }
      this.displayItems = orderBy(this.displayItems, ['item.name'], ['asc']);
      this.isLoading = false;
    });
}
  toList = () => this.location.back();

  createStock = () => {
    this.modalService
      .create({
        nzTitle: 'Create stock',
        nzContent: CreateStockComponent,
        nzData: {
          warehouseId: this.id,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  };

  modifyStock = (item) => {
    this.modalService
      .create({
        nzTitle: 'Modify stock',
        nzContent: WarehouseItemModifyComponent,
        nzData: {
          item: item,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  };

  addStock = (warehouseItemId) => {
    this.modalService
      .create({
        nzTitle: 'Add stock',
        nzContent: WarehouseAddStockComponent,
        nzData: {
          warehouseItemId: warehouseItemId,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  };

  removeStock = (warehouseItemId, amount) => {
    this.modalService
      .create({
        nzTitle: 'Remove stock',
        nzContent: WarehouseRemoveStockComponent,
        nzData: {
          warehouseItemId: warehouseItemId,
          currentAmount: amount,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  };

  transferStock = (item) => {
    this.modalService
      .create({
        nzTitle: 'Transfer stock',
        nzContent: WarehouseTransferStockComponent,
        nzData: {
          item: item,
          currentWarehouse: this.item,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  };

  stockLogs = (id, item) => {
    this.modalService.create({
      nzTitle: 'Stock logs',
      nzContent: WarehouseStockLogsComponent,
      nzData: {
        id: id,
        item: item,
      },
      nzWidth: '80%',
      nzFooter: null,
    });
  };

  resetSearchValue(index) {
    this.itemsColumns[index].searchValue = '';
    this.applySearches();
  }

  applySearches() {
    this.displayItems = this.item.items;
    if (this.itemsColumns[0].searchValue.length > 0)
      this.displayItems = this.displayItems.filter((x) =>
        x.item.name
          .toLowerCase()
          .includes(this.itemsColumns[0].searchValue.toLowerCase())
      );

    if (this.itemsColumns[1].searchValue.length > 0)
      this.displayItems = this.displayItems.filter((x) =>
        x.condition
          .toLowerCase()
          .includes(this.itemsColumns[1].searchValue.toLowerCase())
      );

    if (this.itemsColumns[3].searchValue.length > 0)
      this.displayItems = this.displayItems.filter(
        (x) =>
          x.item.referenceNumber &&
          x.item.referenceNumber
            .toLowerCase()
            .includes(this.itemsColumns[3].searchValue.toLowerCase())
      );

    if (this.itemsColumns[4].searchValue.length > 0)
      this.displayItems = this.displayItems.filter(
        (x) =>
          x.item.brand &&
          x.item.brand
            .toLowerCase()
            .includes(this.itemsColumns[4].searchValue.toLowerCase())
      );
  }

  generateQrCodeLink = (item) =>
    encodeURI(
      `/qr/generator?text=<div><h4><b> ${item.item.name}</b></h4></div><div><h5> ${item.item.referenceNumber}</h5></div><div><h5> ${item.item.brand}</h5></div> <div><h5>${this.item.name}</h5>&value=warehouse-item||${item.id}`
    );
}

