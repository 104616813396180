import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class GraphQlService {
  constructor(
    private messageService: NzMessageService,
    private apollo: Apollo,
  ) { }

  get(query, serverPath = 'common') {
    return this.apollo.use(serverPath).query({
      fetchPolicy: 'no-cache',
      query: gql`
        ${query}
      `,
    });
  }

 

  displayErrors = (errors) => {
    if (!errors) return;
    errors.forEach((error) => {
      if (
        error.extensions &&
        error.extensions.data &&
        error.extensions.data.Code
      ) {
        this.messageService.error(error.extensions.data.Code);
      }
    });
  };
}
