import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { GraphQlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-users-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.less'],
})
export class UsersModifyComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() item;
  @Input() roles = [];
  selectedRoles = [];

  form: FormGroup;

  constructor(private apollo: Apollo, private modalRef: NzModalRef, private graphQlService: GraphQlService) {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null),
      password2: new FormControl(null),
      isActive: new FormControl(null),
      roles: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.roles = this.nzModalData.roles;
    this.item = this.nzModalData.item;

    if (this.item) {
      this.form.patchValue(this.item);
      this.selectedRoles = this.item.roles.map(x => x.id);
    }
  }

  submit() {
    if (this.form.status === 'INVALID') return;
    var formValue = {
      username: this.form.get(`username`).value,
      email: this.form.get(`email`).value,
      password: this.form.get(`password`).value,
      roles: this.selectedRoles
    };
    if (this.item) {
      formValue['id'] = this.form.get(`id`).value;
      formValue['isActive'] = this.form.get(`isActive`).value;
    } else {
      this.form.get(`password2`).setValue(this.form.get(`password`).value);
    }
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
        mutation action($data: ${this.item ? 'UpdateUserCommand' : 'RegisterUserCommand'
          }) {
          ${this.item ? 'updateUser' : 'createUser'}(data: $data)
        }
      `,
        variables: {
          data: formValue,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.modalRef.close('ok');
      });
  }
}
