import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GraphQlService } from 'src/app/services/graphql.service';
import { SupplierModifyComponent } from '../modify/modify.component'

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class SuppliersListComponent implements OnInit {
  isLoading = false;

  list = [];
  displayList = [];

  tableColumns = [
    {
      label: 'Name',
      dataIndex: 'name',
      search: true,
      searchValue: undefined,
    },
    {
      label: 'Phone number',
      dataIndex: 'phoneNumber',
      search: true,
      searchValue: undefined,
    }];

  constructor(private graphQlService: GraphQlService, private modalService: NzModalService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.isLoading = true;
    this.graphQlService.get(`{ suppliers { id name phoneNumber } }`).subscribe((r: any) => {
      this.list = r.data.suppliers;
      this.displayList = this.list;
      console.log('r', r)
      this.isLoading = false;
    })
  }

  newSupplier() {
    this.modalService
      .create({
        nzTitle: 'Add new supplier',
        nzFooter: null,
        nzContent: SupplierModifyComponent,
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  editSupplier(item) {
    this.modalService
      .create({
        nzTitle: 'Add new supplier',
        nzFooter: null,
        nzContent: SupplierModifyComponent,
        nzData: {
          item: item
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

}
