<nz-spin [nzSpinning]="isLoading">
  <nz-page-header (nzBack)="toList()" nzBackIcon [nzTitle]="item?.name">
    <nz-page-header-extra>
      <button
        nz-button
        nzShape="round"
        style="margin-right: 3px"
        (click)="createStock()"
      >
        <i class="fas fa-plus" style="margin-right: 5px"></i> Add new item to
        warehouse
      </button>
      <!-- <button nz-button nzShape="round" (click)="stockLogs()">
        <i class="fas fa-list"></i>
      </button> -->
      
    </nz-page-header-extra>
  </nz-page-header>
<nz-card  >
  <div nz-row>
    <div nz-col nzSpan="16">
      <h2>Stock</h2>
    </div>
    <div nz-col nzSpan="8" style="float: right;">
        <div style="display: inline; margin-right: 10px">Type:</div>
        <div style="display: inline;">
          <nz-select
            [(ngModel)]="stockFilterItemType"
            style="width: 150px"
            (ngModelChange)="load()"
          >
            <nz-option nzValue="All" nzLabel="All"></nz-option>
            <nz-option nzValue="Consumable" nzLabel="Consumable"></nz-option>
            <nz-option nzValue="Equipment" nzLabel="Equipment"></nz-option>
            <nz-option nzValue="Asset" nzLabel="Asset"></nz-option>
            
          </nz-select>
        </div>
        <nz-select [(ngModel)]="pageSize" style="max-width: 150px; margin-left: 5px;">
          <nz-option nzValue="20" nzLabel="20  / page"></nz-option>
          <nz-option nzValue="30" nzLabel="30  / page"></nz-option>
          <nz-option nzValue="40" nzLabel="40  / page"></nz-option>
          <nz-option nzValue="50" nzLabel="50  / page"></nz-option>
          <nz-option nzValue="150" nzLabel="150  / page"></nz-option>
          <nz-option nzValue="500" nzLabel="500  / page"></nz-option>
          <!-- adicione mais opções conforme necessário -->
        </nz-select>
        
        
    </div>
  </div>
</nz-card >
  
  <nz-table
    #stockTable
    [(nzPageSize)]="pageSize"
    nzSize="small"
    [nzData]="displayItems"
    style="min-width: 100%"
    [nzScroll]="{ x: '800px' }"
  >
    <thead>
      <tr>
        <ng-container *ngFor="let column of itemsColumns; index as i">
          <th
          nzCustomFilter
          *ngIf="
            !column.filterItemType ||
            (column.filterItemType &&
                column.filterItemType.includes(stockFilterItemType))
          "
        >
            {{ column.label }}
            <nz-filter-trigger
              *ngIf="column.search"
              [nzVisible]="column.isVisible"
              [nzActive]="column.searchValue.length > 0"
              [nzDropdownMenu]="menu"
            >
              <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <div  class="ant-table-filter-dropdown">
                <div class="search-box">
                  <input
                    type="text"
                    nz-input
                    [(ngModel)]="column.searchValue"
                    placeholder="Search {{ column.label }}"
                  />
                  <button
                    nz-button
                    nzSize="small"
                    nzType="primary"
                    class="search-button"
                    (click)="applySearches()"
                  >
                    Search
                  </button>
                  <button
                    nz-button
                    nzSize="small"
                    (click)="resetSearchValue(i)"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </nz-dropdown-menu>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of stockTable.data">
        <td>
          <a [routerLink]="['/item', item.item.id]">{{ item.item.name }}</a>
        </td>
        <td *ngIf="stockFilterItemType === 'Asset' || stockFilterItemType === 'All' ">
          {{ item.item.serialNumber }}
        </td>
        <td>{{ item.isUsed && item.isUsed === true ? "Used" : "New" }}</td>
        <td>{{ item.item.referenceNumber }}</td>
        <td>{{ item.item.brand }}</td>
        <td
          *ngIf="
            stockFilterItemType === 'Consumable' ||
            stockFilterItemType === 'Equipment' || stockFilterItemType === 'All' 
          "
        >
          {{ item.amount }}
        </td>
        <td>
          <button
            *ngIf="
              item.item.type === 'Consumable' || item.item.type === 'Equipment'
            "
            nz-button
            nzShape="round"
            nzSize="small"
            style="margin-right: 3px"
            (click)="addStock(item.id)"
            nz-tooltip="Add Stock"
          >
            <i class="fas fa-plus"></i>
          </button>
          <button
            *ngIf="
              item.item.type === 'Consumable' || item.item.type === 'Equipment'
            "
            nz-button
            nzShape="round"
            nzSize="small"
            style="margin-right: 3px"
            nz-tooltip="Remove Stock"
            (click)="removeStock(item.id, item.amount)"
          >
            <i class="fas fa-minus"></i>
          </button>
          <button
            nz-button
            nzShape="round"
            nzSize="small"
            style="margin-right: 3px"
            (click)="transferStock(item)"
            nz-tooltip="Transfer Stock"
          >
            <i class="fas fa-exchange-alt"></i>
          </button>

          <button nz-button  nzSize="small" nzShape="round" nz-dropdown [nzDropdownMenu]="menu" nz-icon nzType="down">
            </button>
         
         
          <nz-dropdown-menu nzShape="round" #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item   *ngIf="
               item.item.type === 'Consumable' || item.item.type === 'Equipment' &&
              item.condition === 'New'
            " (click)="modifyStock(item)">
                <i class="fas fa-cogs" style="margin-right: 7px;"></i> Low Stock
              </li>
              <li nz-menu-item (click)="stockLogs(item.id, item.item)">
                <i class="fas fa-list" style="margin-right: 7px;"></i>Stock Logs
              </li>
              <li nz-menu-item  ><a href="{{ generateQrCodeLink(item) }}"  target="_blank">
                <i class="fas fa-qrcode" style="margin-right: 7px;"></i> <span>Generate Qr Code</span>
              </a></li>
            </ul>
          </nz-dropdown-menu>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
