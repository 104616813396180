import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispatchRequestsListComponent } from './list/list.component';
import { SharedModule } from '../shared.module';
import { DispatchRequestsViewComponent } from './view/view.component';
import { DispatchRequestsNewComponent } from './new/new.component';
import { DeliveriesModule } from '../deliveries/deliveries.module';
import { DispatchRequestsNewMassDispatchComponent } from './new-mass-dispatch/new-mass-dispatch.component';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [
    DispatchRequestsListComponent,
    DispatchRequestsViewComponent,
    DispatchRequestsNewComponent,
    DispatchRequestsNewMassDispatchComponent,
  ],
  imports: [CommonModule, SharedModule, DeliveriesModule, NzModalModule],
})
export class DispatchRequestsModule {}
