<div class="logo"></div>
<ul nz-menu  nzMode="inline" >
  <li nz-menu-item *ngIf="roles.includes('administrator')">
    <a [routerLink]="['/']">
      <i nz-icon nzType="appstore"></i>
      <span>Dashboard</span>
    </a>
  </li>
  <li nz-menu-item *ngIf="roles.includes('administrator')">
    <a [routerLink]="['/warehouses']">
      <i nz-icon nzType="shop"></i>
      <span>Warehouses</span>
    </a>
  </li>
  <li nz-menu-item *ngIf="roles.includes('administrator')">
    <a [routerLink]="['/items']">
      <i nz-icon nzType="tags"></i>
      <span>Items</span>
    </a>
  </li>
  <li nz-menu-item *ngIf="roles.includes('administrator')">
    <a [routerLink]="['/suppliers']">
      <i nz-icon nzType="container"></i>
      <span>Suppliers</span>
    </a>
  </li>
  <li
    nz-menu-item
    [routerLink]="['/clients']"
    *ngIf="roles.includes('administrator')"
  >
    <i nz-icon nzType="team"></i>
    <span>Clients</span>
  </li>
  <li
    nz-menu-item
    [routerLink]="['/locations']"
    *ngIf="roles.includes('administrator')"
  >
    <i nz-icon nzType="environment"></i>
    <span>Locations</span>
  </li>
  <li
    nz-menu-item
    [routerLink]="['/dispatch-requests']"
    *ngIf="roles.includes('administrator')"
  >
    <i nz-icon nzType="send"></i>
    <span>Dispatch requests</span>
  </li>
  <li
    nz-menu-item
    [routerLink]="['/deliveries']"
    *ngIf="roles.includes('administrator') || roles.includes('driver')"
  >
    <i nz-icon nzType="select"></i>
    <span>Deliveries</span>
  </li>

  <li
    nz-menu-item
    [routerLink]="['/users']"
    *ngIf="roles.includes('administrator')"
  >
    <i nz-icon nzType="user"></i>
    <span>Users</span>
  </li>
  <li nz-menu-item [routerLink]="['/qr-scanner']" routerLinkActive="active">
    <i nz-icon nzType="scan"></i>
    <span>QR Scanner</span>
  </li>
  <li nz-submenu  nzTitle="Config" nzIcon="setting" *ngIf="roles.includes('administrator')">
    
    <ul>
      <li nz-menu-item [routerLink]="['/configs/items/categories']">
        Items categories
      </li>
      <li nz-menu-item [routerLink]="['/configs/items/kits']">Items kits</li>
    </ul>
  </li>
  <li nz-menu-item  (click)="switchTheme()">
    <i nz-icon nzType="swap"></i>
    <span>Switch theme</span>
  </li>
  <li nz-menu-item (click)="logout()">
    <i nz-icon nzType="logout"></i>
    <span>Logout</span>
  </li>
</ul>
