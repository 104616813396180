import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GraphQlService } from 'src/app/services/graphql.service';
import { UsersModifyComponent } from '../modify/modify.component';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-users-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class UsersListComponent implements OnInit {
  isLoading = false;

  users = [];
  roles = [];

  constructor(
    private modalService: NzModalService,
    private graphQlService: GraphQlService
  ) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.isLoading = true;
    this.graphQlService
      .get('{ users { id username isActive email roles { id value } } }')
      .subscribe((r: any) => {
        this.users = orderBy(r.data.users, (x) => x.isActive, 'desc');
        this.isLoading = false;
      });
      this.graphQlService
      .get('{ roles { id symbol value } }')
      .subscribe((r: any) => {
        this.roles = r.data.roles;
        this.isLoading = false;
      });
  }

  newUser() {
    this.modalService
      .create({
        nzTitle: 'Add new user',
        nzFooter: null,
        nzContent: UsersModifyComponent,
        nzData: {
          roles: this.roles
        },
        nzWidth: '80%',
        nzMaskClosable: false
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  editItem(item) {
    this.modalService
      .create({
        nzTitle: 'Edit user',
        nzFooter: null,
        nzContent: UsersModifyComponent,
        nzData: {
          item: item,
          roles: this.roles
        },
        nzWidth: '80%',
        nzMaskClosable: false
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }
}
