import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {

  list = [];
  transferWarehouseId;

  constructor() { }

}
