<nz-table #logsTable nzSize="small" [nzData]="list" style="min-width: 100%;" [nzScroll]="{ x: '800px' }">
  <thead>
    <tr>
      <th>Date</th>
      <th>Employee</th>
      <th>Item</th>
      <th>Action</th>
      <th>Amount</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let i of logsTable.data">
      <td>{{ i.createdOn }}</td>
      <td>{{ i.user?.username }}</td>
      <td>{{ item.name }}</td>
      <td>
        <span *ngIf="i.actionType === 'Modify' && i.payload.Action === '+'">Added</span>
        <span *ngIf="i.actionType === 'Modify' && i.payload.Action === '-'">Removed</span>
        <span *ngIf="i.actionType === 'Create'">Created</span>
      </td>
      <td>{{ i.payload.Amount }}</td>
    </tr>
  </tbody>
</nz-table>
