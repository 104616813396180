<nz-spin [nzSpinning]="isLoading"></nz-spin>
<nz-page-header nzTitle="Deliveries">
  <nz-page-header-content>
    <div nz-row>
      <div nz-col nzSpan="24">
        <button nz-button nzShape="round" style="float: right; margin-top: 5px;" (click)="toggleFilterVisible()">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div nz-row *ngIf="filterVisible">
      <div nz-col nzSpan="12" style="text-align: center; margin-top: 5px;">
        <input nz-input placeholder="Search for Client" type="text" [(ngModel)]="clientNameSearch" />
        <!-- <button nz-button nzType="primary" (click)="load()">Search</button> -->
      </div>
      <div nz-col nzSpan="12" style="text-align: center; margin-top: 5px;">
        <input nz-input placeholder="Search for Location" type="text" [(ngModel)]="clientLocationNameSearch" />
        <!-- <button nz-button nzType="primary" (click)="load()">Search</button> -->
      </div>
      <div nz-col nzSpan="24" style="margin-top: 15px">
        <button nz-button nzType="primary" style="float: right;" nzShape="round" (click)="load()">
          Search <i style="margin-left: 5px;" class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div nz-col nzSpan="8">
      <div>
        <div style="display: inline; margin-right: 10px">Status:</div>
        <div style="display: inline">
          <nz-select nzMode="multiple" [(ngModel)]="statusSearch" style="width: 300px" (ngModelChange)="load()">
            <nz-option nzValue="New" nzLabel="New"></nz-option>
            <nz-option nzValue="InTransit" nzLabel="In transit"></nz-option>
            <nz-option nzValue="Completed" nzLabel="Completed"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="8">
      <!--    <button nz-button nzShape="round" style="float: right; margin-top: 5px;" [routerLink]="['/']">
        <i class="fas fa-search"></i>
      </button> -->
    </div>
  </nz-page-header-content>
</nz-page-header>


<div nz-row nzGutter="12">
  <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="8" *ngFor="let i of list">
    <nz-card nzSize="small" nzTitle="Delivery {{ i.id }}" style="margin-bottom: 12px" nzHoverable
      [nzActions]="[actionOpen]">
      <nz-tag [nzColor]="getStatusColor(i.status)" style="position: absolute; top: 8px; right: 16px;">
        {{ i.status }}
      </nz-tag>

      <nz-row [nzGutter]="16">
        <nz-col nzSpan="12">
          <div><b>Clients:</b> {{ i.clients }}</div>
        </nz-col>
        <nz-col nzSpan="12">
          <div><b>Locations:</b> {{ i.clientsLocations }}</div>
        </nz-col>
        <nz-col nzSpan="12">
          <div><b>Items Count:</b> {{ i.itemsCount }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="i.createdOn">
          <div><b>Created On:</b> {{ formatDate(i.createdOn) }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="i.startDateTime">
          <div><b>Start Date:</b> {{ formatDate(i.startDateTime) }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="i.finishDateTime">
          <div><b>Finish Date:</b> {{ formatDate(i.finishDateTime) }}</div>
        </nz-col>
      </nz-row>

      <ng-template #actionOpen>
        <div nz-tooltip nzTooltipTitle="Open" [routerLink]="['/delivery', i.id]">
          <i class="far fa-eye"></i>
        </div>
      </ng-template>
    </nz-card>

  </div>
</div>