import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

interface Delivery {
  id: number;
  status: string;
  dispatches: Dispatch[];
}

interface Dispatch {
  id: number;
  type: string;
  status: string;
  targetWarehouse: Warehouse;
  warehouse: Warehouse;
  client: Client;
  clientLocation: ClientLocation;
  items: Item[];
}

interface Warehouse {
  id: number;
  name: string;
  geoLocationLat: number;
  geoLocationLong: number;
}

interface Client {
  id: number;
  name: string;
}

interface ClientLocation {
  id: number;
  name: string;
  postalCode: string;
  street: string;
  number: string;
  geoLocationLat: number;
  geoLocationLong: number;
}

interface Item {
  amount: number;
  isUsed: boolean;
  item: ItemInfo;
}

interface ItemInfo {
  id: number;
  name: string;
}

@Component({
  selector: 'app-deliveries-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.less'],
})
export class DeliveriesModifyComponent implements OnInit {
  id: number;
  delivery: Delivery;
  isLoading: boolean;

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.isLoading = true;
    this.apollo
      .use('common')
      .query<{ delivery: Delivery }>({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            delivery(id: "${this.id}") {
              id
              status
              dispatches {
                id
                type
                status
                createdOn
                startDateTime
                finishDateTime
                targetWarehouse {
                  id
                  name
                }
                warehouse {
                  id
                  name
                  geoLocationLat
                  geoLocationLong
                }
                client {
                  id
                  name
                }
                clientLocation {
                  id
                  name
                  postalCode
                  street
                  number
                  geoLocationLat
                  geoLocationLong
                }
                items {
                  amount
                  isUsed
                  item {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
      })
      .subscribe((result) => {
        this.delivery = result.data.delivery;
        this.isLoading = false;
      });
  }

  actionOpen(id: number): void {
    // Implement your actionOpen logic here based on the dispatch ID
    console.log('Open Dispatch ID:', id);
  }
}
