<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Warehouse
      </div>
      <div nz-col nzSpan="12">
        <nz-select style="width: 100%" formControlName="targetWarehouseId">
          <nz-option *ngFor="let i of warehouses" [nzValue]="i.id" [nzLabel]="i.name"></nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Amount (Max: {{ item.amount }})
      </div>
      <!-- <div nz-col nzSpan="8">
        <nz-slider [nzMin]="0" [nzMax]="item.amount" [nzMarks]="marks" formControlName="amount"></nz-slider>
      </div> -->
      <div nz-col nzSpan="12">
        <input type="number" min="1" [max]="item.amount" nz-input formControlName="amount" placeholder="Amount" />
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">Add</button>
    </div>
  </form>

  <div *ngIf="list.length > 0">
    <nz-divider></nz-divider>
    <h3>Added items</h3>

    <nz-table nzTemplateMode nzSize="small">
      <thead>
        <tr>
          <th>Target warehouse</th>
          <th>Item</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of list">
          <td>{{ data.warehouse.name }}</td>
          <td><a [routerLink]="['items', data.item.item.id]" target="_blank">{{ data.item.item.name }}</a></td>
          <td>{{ data.amount }}</td>
          <td>
            <div (click)="removeItem(data.item)">
              <i class="far fa-trash-alt" style="color: red"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <div style="text-align: center; margin-top: 10px">
      <button nz-button nzType="primary" nzShape="round" (click)="createDispatchRequest()">Create dispatch
        request</button>
    </div>
  </div>

</nz-spin>