import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  roles = [];
  isDarkTheme: boolean = true;

  // Função para alternar o tema
  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
  }

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.apiUrl = environment.apiUrl;

    this.authService.isLoggedIn.subscribe((r) => (this.isLoggedIn = r));
    this.authService.isLoggedIn.emit(localStorage.getItem(`jwtToken`) !== null);
  }
}
