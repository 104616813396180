import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-items-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.less'],
})
export class ItemsModifyComponent implements OnInit {
  isLoading;

  categories = [];

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });
  @Input() item;

  form: FormGroup;

  constructor(private apollo: Apollo, private modalRef: NzModalRef) {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      serialNumber: new FormControl(null),
      referenceNumber: new FormControl(null),
      description: new FormControl(null),
      price: new FormControl(null),
      brand: new FormControl(null),
      categoryId: new FormControl(null),
      type: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.item = this.nzModalData.item;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: `no-cache`,
        query: gql`
          {
            itemCategories {
              id
              value
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.categories = [...r.data.itemCategories];
      });

    if (this.item)
      this.form.patchValue({
        ...this.item,
        categoryId: this.item.category?.id,
      });
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
        mutation action($data: CreateItemCommand) {
          ${this.item ? 'updateItem' : 'createItem'}(data: $data)
        }
      `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.modalRef.close('ok');
      });
  }
}
