import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { WarehouseService } from '../warehouse.service';

@Component({
  selector: 'app-warehouse-transfer-stock',
  templateUrl: './transfer-stock.component.html',
  styleUrls: ['./transfer-stock.component.less'],
})
export class WarehouseTransferStockComponent implements OnInit {
  marks = {};

  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() item;
  @Input() currentWarehouse;

  warehouses = [];

  form: FormGroup;

  list = [];

  constructor(
    private apollo: Apollo,
    private modalRef: NzModalRef,
    private warehouseService: WarehouseService,
    private messageService: NzMessageService
  ) {
    this.form = new FormGroup({
      warehouseId: new FormControl(null, [Validators.required]),
      warehouseItemId: new FormControl(null, [Validators.required]),
      targetWarehouseId: new FormControl(null, [Validators.required]),
      amount: new FormControl(1, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.item = this.nzModalData.item;
    this.currentWarehouse = this.nzModalData.currentWarehouse;

    console.log('item', this.item)
    this.marks[1] = '1';
    this.marks[Math.round(this.item.amount / 2)] = `${Math.round(
      this.item.amount / 2
    )}`;
    this.marks[this.item.amount] = `${this.item.amount}`;
    console.log('amount', this.item.amount)

    this.form.get(`warehouseId`).setValue(this.currentWarehouse.id);
    this.form.get(`warehouseItemId`).setValue(this.item.id);

    this.form.get(`amount`).valueChanges.subscribe(r => {
      if (r > this.item.amount) this.form.get(`amount`).setValue(this.item.amount);
    })

    this.list = this.warehouseService.list;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouses {
              id
              name
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.warehouses = r.data.warehouses.filter(
          (x) => x.id !== this.currentWarehouse.id
        );
        this.isLoading = false;
      });
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    if (this.warehouseService.list.find(x => x.warehouseItemId === this.item.id)) {
      this.messageService.error(`Item already added!`);
      return;
    }

    var item = this.form.getRawValue();
    item['item'] = this.item;
    item['warehouse'] = this.warehouses.find(x => x.id === this.form.get(`targetWarehouseId`).value);
    this.warehouseService.list.push(item);
    this.list = this.warehouseService.list;
  }

  removeItem = (item) => {
    var items = this.warehouseService.list.filter(x => x.warehouseItemId != item.id);
    this.warehouseService.list = items;
    this.list = items;
  }

  createDispatchRequest = () => {
    console.log('test', this.list.map(x => ({ warehouseId: x.warehouseId, warehouseItemId: x.warehouseItemId, targetWarehouseId: x.targetWarehouseId, amount: x.amount })))
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: [TransferWarehouseItemCommand]) {
            transferWarehouseItem(data: $data)
          }
        `,
        variables: {
          data: this.list.map(x => ({ warehouseId: x.warehouseId, warehouseItemId: x.warehouseItemId, targetWarehouseId: x.targetWarehouseId, amount: x.amount })),
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.warehouseService.list = [];
        this.list = [];
        this.modalRef.close('ok');
      });
  }
}
