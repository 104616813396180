import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    var currentUser = localStorage.getItem('jwtToken');
    if (!currentUser) {
      localStorage.setItem(`app_redirectTo`, window.location.pathname);

      this.router.navigateByUrl('/login');
      return false;
    }
    return true;
  }
}
