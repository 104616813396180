import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-warehouse-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.less'],
})
export class WarehouseNewComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });
  @Input() item;

  form: FormGroup;

  constructor(private apollo: Apollo, private modalRef: NzModalRef) {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      address: new FormControl(null),
      postalCode: new FormControl(null),
      region: new FormControl(null),
      geoLocationLat: new FormControl(null),
      geoLocationLong: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.item = this.nzModalData.item;
    if (this.item) this.form.patchValue(this.item);
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
        mutation action($data: CreateWarehouseCommand) {
          ${this.item ? 'updateWarehouse' : 'createWarehouse'}(data: $data)
        }
      `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.modalRef.close('ok');
      });
  }
}
