import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { ClientsLocationsListComponent } from './list/list.component';
import { ClientsLocationsViewComponent } from './view/view.component';

export const routes: Routes = [
  {
    path: 'locations',
    component: ClientsLocationsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'location/:id',
    component: ClientsLocationsViewComponent,
    canActivate: [AuthGuard],
  },
];
