<form>
  <div nz-row nzGutter="12">
    <div nz-col nzSpan="24" *ngFor="let delivery of delivery">
      <nz-card
        nzSize="small"
        [nzTitle]="'Delivery ' + delivery.id"
        style="margin-bottom: 12px"
        nzHoverable
        [nzActions]="[actionOpen(delivery.id)]"
      >
        <div>Client: {{ getClientNames(delivery.dispatches) }}</div>
        <div>Location: {{ getClientLocationNames(delivery.dispatches) }}</div>
        <ul nz-list nzBordered nzSize="default" style="margin-bottom: 5px; margin-top: 5px">
          <li nz-list-item *ngFor="let dispatch of delivery.dispatches" nzNoFlex>
            <div>Dispatch ID: {{ dispatch.id }}</div>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                Amount: {{ getItemsCount(dispatch.items) }}
              </nz-list-item-action>
            </ul>
            <ul nz-list-item-actions>
              <nz-list-item-action>
                <button nz-button nzType="primary" nzShape="round" (click)="actionOpen(dispatch.id)">
                  View
                </button>
              </nz-list-item-action>
            </ul>
            <div *ngFor="let item of dispatch.items">
              <div>Item ID: {{ item.item.id }}</div>
              <div>Item Name: {{ item.item.name }}</div>
              <div>Amount: {{ item.amount }}</div>
            </div>
          </li>
        </ul>
      </nz-card>
    </div>
  </div>

  <div style="text-align: center">
    <button nz-button nzType="primary" nzShape="round">
      {{ id ? 'Update' : 'Create' }}
    </button>
  </div>
</form>
