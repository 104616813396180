<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Items categories">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="new()">
        Add new category
      </button>
    </nz-page-header-extra>
  </nz-page-header>

  <nz-table #basicTable nzSize="small" [nzData]="list" style="min-width: 100%;" [nzScroll]="{ x: '800px' }">
    <thead>
      <tr>
        <th>Name</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of basicTable.data">
        <td>{{ item.value }}</td>
        <td>
          <button
            nz-button
            nzShape="round"
            nzSize="small"
            style="margin-right: 3px"
            (click)="edit(item)"
          >
            <i class="fas fa-cogs"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>
