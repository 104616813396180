import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { keys, groupBy, sum, uniq, includes } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-deliveries-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class DeliveriesViewComponent implements OnInit {
  isLoading;

  id;
  item;
  displayItems = [];
  dispatches = [];
  selectedItem: any;
  routeOrganizer = {
    sourceWarehouse: undefined,
    targetClientLocations: [],
  };

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo,
    private messageService: NzMessageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((r) => {
      this.id = r['id'];
      this.load();
    });
  }

  toList = () => this.location.back();

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            delivery(id:"${this.id}") {
              id
              status
              createdOn
              startDateTime
              finishDateTime
              dispatches {
                id
                type
                status
                targetWarehouse{
                  id
                  name
                }
                warehouse{
                  id
                  name
                  geoLocationLat
                  geoLocationLong
                }
                client {
                  id
                  name
                }
                clientLocation {
                  id
                  name
                  postalCode
                  street
                  number
                  geoLocationLat
                  geoLocationLong
                }
                items {
                  amount
                  isUsed
                  item{
                    id
                    name
                  }
                }
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.item = r.data.delivery;
        this.dispatches = [];
        var dispatchesByType = groupBy(this.item.dispatches, (x) => x.type);

        keys(dispatchesByType).forEach((dispatchType) => {
          var dispatch = {
            type: dispatchType,
            entries: []
          };

          if (dispatch.type === 'transfer') {
            var dispatchByTargetWarehouse = groupBy(
              dispatchesByType[dispatchType],
              (x) => x.targetWarehouse.id
            );
            keys(dispatchByTargetWarehouse).forEach((targetWarehouseId) => {
              var dispatchBySourceWarehouse = groupBy(
                dispatchByTargetWarehouse[targetWarehouseId].filter(
                  (x) => x.targetWarehouse.id === targetWarehouseId
                ),
                (x) => x.warehouse.id
              );
              keys(dispatchBySourceWarehouse).forEach((sourceWarehouseId) => {
                dispatch.entries.push({
                  dispatchRequestIds: uniq(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.id
                    )
                  ),
                  statuses: uniq(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.status
                    )
                  ),
                  sourceWarehouse:
                    dispatchBySourceWarehouse[sourceWarehouseId][0].warehouse,
                  targetWarehouse:
                    dispatchBySourceWarehouse[sourceWarehouseId][0]
                      .targetWarehouse,
                  items: this.mergeItems(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.items
                    )
                  ),
                });
              });
            });
          }

          if (dispatch.type === 'dispatch') {
            var dispatchByClientLocation = groupBy(
              dispatchesByType[dispatchType],
              (x) => x.clientLocation.id
            );
            keys(dispatchByClientLocation).forEach((clientLocationId) => {
              var dispatchBySourceWarehouse = groupBy(
                dispatchByClientLocation[clientLocationId].filter(
                  (x) => x.clientLocation.id === clientLocationId
                ),
                (x) => x.warehouse.id
              );
              keys(dispatchBySourceWarehouse).forEach((sourceWarehouseId) => {
                this.routeOrganizer.sourceWarehouse =
                  dispatchBySourceWarehouse[sourceWarehouseId][0].warehouse;
                this.routeOrganizer.targetClientLocations.push(
                  dispatchBySourceWarehouse[sourceWarehouseId][0].clientLocation
                );

                dispatch.entries.push({
                  dispatchRequestIds: uniq(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.id
                    )
                  ),
                  statuses: uniq(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.status
                    )
                  ),
                  sourceWarehouse:
                    dispatchBySourceWarehouse[sourceWarehouseId][0].warehouse,
                  clientLocation:
                    dispatchBySourceWarehouse[sourceWarehouseId][0]
                      .clientLocation,
                  client:
                    dispatchBySourceWarehouse[sourceWarehouseId][0].client,
                  items: this.mergeItems(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.items
                    )
                  ),
                });
              });
            });
          }

          if (dispatch.type === 'return') {
            var dispatchByClientLocation = groupBy(
              dispatchesByType[dispatchType],
              (x) => x.clientLocation.id
            );
            keys(dispatchByClientLocation).forEach((clientLocationId) => {
              var dispatchBySourceWarehouse = groupBy(
                dispatchByClientLocation[clientLocationId].filter(
                  (x) => x.clientLocation.id === clientLocationId
                ),
                (x) => x.targetWarehouse.id
              );
              keys(dispatchBySourceWarehouse).forEach((sourceWarehouseId) => {
                dispatch.entries.push({
                  dispatchRequestIds: uniq(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.id
                    )
                  ),
                  statuses: uniq(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.status
                    )
                  ),
                  targetWarehouse:
                    dispatchBySourceWarehouse[sourceWarehouseId][0]
                      .targetWarehouse,
                  clientLocation:
                    dispatchBySourceWarehouse[sourceWarehouseId][0]
                      .clientLocation,
                  client:
                    dispatchBySourceWarehouse[sourceWarehouseId][0].client,
                  items: this.mergeItems(
                    dispatchBySourceWarehouse[sourceWarehouseId].map(
                      (x) => x.items
                    )
                  ),
                });
              });
            });
          }

          this.dispatches.push(dispatch);
        });
        this.routeOrganizer.targetClientLocations = [
          this.routeOrganizer.sourceWarehouse,
          ...this.routeOrganizer.targetClientLocations,
        ];
        console.log('dispatches', this.dispatches, this.routeOrganizer);
        this.isLoading = false;
      });
  }
  selectItem(item: any) {
    this.selectedItem = item; 
  }
  mergeItems(items) {
    var results = [];
    items.forEach((item) => {
      item.forEach((innerItem) => {
        if (
          !results.find(
            (x) =>
              x.item.id === innerItem.item.id && x.isUsed === innerItem.isUsed
          )
        )
          results.push(innerItem);
        else
          results.find(
            (x) =>
              x.item.id === innerItem.item.id && x.isUsed === innerItem.isUsed
          ).amount += innerItem.amount;
      });
    });
    return results;
  }

  beginDelivery() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: BeginDeliveryCommand) {
            beginDelivery(data: $data)
          }
        `,
        variables: {
          data: {
            deliveryId: this.id,
          },
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.messageService.success(`Delivery has started.`);
        this.load();
      });
  }

  finishDelivery() {
    var list = [];

    this.dispatches.forEach(dispatch => {
      dispatch.entries.forEach(entry => {
        entry.dispatchRequestIds.forEach(id => list.push(id));
      })
    })

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
        mutation action($data: FinishDispatchRequestDeliveryCommand) {
          finishDispatchRequest(data: $data)
        }
      `,
        variables: {
          data: {
            id: list
          },
        },
      })
      .subscribe(() => {
        this.messageService.success(`Delivery completed!`);
        this.isLoading = false;
      });
  }

  getQrCodeValue = (ids) =>
    `complete-delivery||${ids.map((x) => x).join(`&&`)}`;
  getQrCodeSize = (ids) => {
    if (ids.length < 5) return 150;
    if (ids.length < 10) return 200;
    else return 250;
  };

  canShowQrCodePart = (statuses) => {
    if (includes(statuses, "New")) return true;
    if (includes(statuses, "AssignedToDelivery")) return true;
    if (includes(statuses, "InTransit")) return true;
    return false;
  }
  exportToPDF() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
    const content = [];
    const dispatchChunks = this.chunkArray(this.dispatches, 4);
  
    dispatchChunks.forEach(chunk => {
      let tableBody = [];
      let dispatchCount = 0;
  
      chunk.forEach((dispatch, index) => {
        dispatch.entries.forEach(entry => {
          const dispatchInfo = [
            entry.sourceWarehouse?.name ? { text: `Source warehouse: ${entry.sourceWarehouse.name}`, style: 'info' } : null,
            entry.targetWarehouse?.name ? { text: `Target warehouse: ${entry.targetWarehouse.name}`, style: 'info' } : null,
            entry.client?.name ? { text: `Client: ${entry.client.name}`, style: 'info' } : null,
            entry.clientLocation?.name ? { text: `Location: ${entry.clientLocation.name}`, style: 'info' } : null,
          ].filter(info => info !== null); 
          const dispatchBlock = {
            stack: [
              { text: ' ' },
              {
                qr: this.getQrCodeValue(entry.dispatchRequestIds || []),
                fit: 100,
                margin: [0, 10, 0, 10],
              },
              ...dispatchInfo,
            ],
            style: 'dispatchCard'
          };
  
          tableBody.push(dispatchBlock);
          dispatchCount++;
  
          if (dispatchCount === 4) {
            const table = {
              table: {
                widths: ['50%', '50%'],
                body: [
                  [tableBody[0], tableBody[1]],
                  [tableBody[2], tableBody[3]],
                ],
              },
              layout: {
                defaultBorder: false,
              },
            };
  
            content.push(table);
  
            tableBody = [];
            dispatchCount = 0;
          }
        });
      });
  
      if (dispatchCount > 0) {
        const remainingDispatches = 4 - dispatchCount;
        for (let i = 0; i < remainingDispatches; i++) {
          tableBody.push({ stack: [{ text: ' ', style: 'dispatchCard' }] });
        }
  
        const table = {
          table: {
            widths: ['50%', '50%'],
            body: [
              [tableBody[0], tableBody[1]],
              [tableBody[2], tableBody[3]],
            ],
          },
          layout: {
            defaultBorder: false,
          },
        };
  
        content.push(table);
      }
    });
  
    const styles = {
      info: { fontSize: 14, alignment: 'center', margin: [0, 0, 0, 5] },
      dispatchCard: { fillColor: 'lightgray', alignment: 'center', margin: [0, 15, 0, 15] }, 
      qrCell: { width: '25%', height: '50%', alignment: 'center' } 
    };
  
    const documentDefinition = {
      pageOrientation: 'landscape',
      pageSize: 'A4',
      content: content,
      styles: styles,
    };
  
    pdfMake.createPdf(documentDefinition).open();
  }
  

  chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }
  formatDate(dateTimeString): string {
    const date = new Date(dateTimeString);
    return format(date, 'dd/MM/yyyy');
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'AssignedToDelivery':
        return 'orange';
      case 'InTransit':
        return 'red';
      case 'New':
        return 'blue';
      default:
        return 'default';
    }
  }
}
