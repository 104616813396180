import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { WarehouseAddStockComponent } from 'src/app/warehouses/add-stock/add-stock.component';
import { WarehouseRemoveStockComponent } from 'src/app/warehouses/remove-stock/remove-stock.component';
import { Barcode, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.less'],
})
export class QrScannerComponent implements OnInit {
  manageStockWarehouseItemId;
  isLoading;
  barcodes: Barcode[] = [];

  constructor(
    // private barcodeScanner: BarcodeScanner,
    private messageService: NzMessageService,
    private modalService: NzModalService,
    private apollo: Apollo
  ) { }

  ngOnInit(): void {
    BarcodeScanner.isSupported().then((result) => {
      if (!result.supported)
        this.messageService.error(`Barcode scanner unsupported!`)

    });
  }

  async scan(): Promise<void> {
    const granted = await this.requestPermissions();
    if (!granted) {
      this.presentAlert();
      return;
    }
    const { barcodes } = await BarcodeScanner.scan();
    const barcodeDataText = barcodes[0].displayValue;

    this.messageService.success(barcodeDataText);
    var data = barcodeDataText.split('||');
    if (data[0] === 'warehouse-item') this.manageStock(data[1]);
    if (data[0] === 'complete-delivery') this.completeDispatch(data[1].split('&&'));
  }

  async requestPermissions(): Promise<boolean> {
    const { camera } = await BarcodeScanner.requestPermissions();
    return camera === 'granted' || camera === 'limited';
  }

  async presentAlert(): Promise<void> {
    this.messageService.error('Please grant camera permission to use the barcode scanner.');
  }

  manageStock(warehouseItemId) {
    this.manageStockWarehouseItemId = warehouseItemId;
  }

  manageStockIncrease(warehouseItemId) {
    this.modalService
      .create({
        nzTitle: 'Add stock',
        nzContent: WarehouseAddStockComponent,
        nzData: {
          warehouseItemId: warehouseItemId,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        this.manageStockWarehouseItemId = undefined;
      });
  }

  manageStockDecrease(warehouseItemId) {
    this.modalService
      .create({
        nzTitle: 'Remove stock',
        nzContent: WarehouseRemoveStockComponent,
        nzData: {
          warehouseItemId: warehouseItemId,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        this.manageStockWarehouseItemId = undefined;
      });
  }

  completeDispatch(ids) {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
        mutation action($data: FinishDispatchRequestDeliveryCommand) {
          finishDispatchRequest(data: $data)
        }
      `,
        variables: {
          data: {
            id: ids
          },
        },
      })
      .subscribe(() => {
        this.messageService.success(`Dispatch requests completed!`);
        this.isLoading = false;
      });
  }
}
