<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Dispatch requests">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="new()">New delivery</button>
      <button nz-button nzShape="round" (click)="newDispatchRequest()">New dispatch request</button>
      <button nz-button nzShape="round" (click)="newMassDispatchRequest()">New mass dispatch request</button>
      <div nz-row>
        <div nz-col nzSpan="24">
          <button nz-button nzShape="round" style="float: right; margin-top: 5px;" (click)="toggleFilterVisible()">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </nz-page-header-extra>
    <nz-page-header-content>
      <div nz-row *ngIf="filterVisible">
        <div nz-col nzSpan="12" *ngFor="let field of filterFields" style="text-align: center; margin-top: 5px;">
          <input nz-input placeholder="Search for {{ field.label }}" [(ngModel)]="field.searchValue" />
        </div>
        <div nz-col nzSpan="24" style="margin-top: 15px">
          <button nz-button nzType="primary" style="float: right;" nzShape="round" (click)="filterData()">
            Search <i style="margin-left: 5px;" class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="12">
          <div>
            <div style="display: inline; margin-right: 10px">Status:</div>
            <div style="display: inline">
              <nz-select [(ngModel)]="statusSearch" style="width: 150px" (ngModelChange)="load()">
                <nz-option nzValue="All" nzLabel="All"></nz-option>
                <nz-option nzValue="New" nzLabel="New"></nz-option>
                <nz-option nzValue="AssignedToDelivery" nzLabel="Assigned to delivery"></nz-option>
                <nz-option nzValue="InTransit" nzLabel="In transit"></nz-option>
                <nz-option nzValue="Completed" nzLabel="Completed"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="12">
          <nz-button-group>
            <button nz-button (click)="selectAll()">Select all</button>
            <button nz-button (click)="unselectAll()">Unselect all</button>
            <button nz-button nz-dropdown [nzDropdownMenu]="menu">Actions <i nz-icon nzType="down"></i></button>
          </nz-button-group>

          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="cancelSelected()">Cancel selected</li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
     
    </nz-page-header-content>
  </nz-page-header>
  

  <div nz-row nzGutter="12">
    <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="8" *ngFor="let item of filteredItems.length ? filteredItems : items">
      <nz-card
      nzSize="small"
      nzTitle="{{ item.type }}"
      style="margin-bottom: 12px"
      nzHoverable
      [nzActions]="[actionSelect, actionOpen]"
    >
      <nz-tag [nzColor]="getStatusColor(item.status)" style="position: absolute; top: 8px; right: 16px;">
        {{ item.status }}
      </nz-tag>
      <nz-row [nzGutter]="16">
        <nz-col nzSpan="12">
          <div><b>Warehouse:</b> {{ item?.warehouse?.name }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="item.type === 'transfer' || item.type === 'return'">
          <div><b>Destination Warehouse:</b> {{ item?.targetWarehouse?.name }}</div>
        </nz-col>
        <nz-col nzSpan="12">
          <div><b>Items Count:</b> {{ item?.items?.length }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="item.type === 'dispatch'">
          <div><b>Client:</b> {{ item?.client?.name }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="item.type === 'dispatch'">
          <div><b>Location:</b> {{ item?.clientLocation?.name }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="item?.createdOn">
          <div><b>Created On:</b> {{ formatDate(item?.createdOn) }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="item?.startDateTime">
          <div><b>Start Date:</b> {{ formatDate(item?.startDateTime) }}</div>
        </nz-col>
        <nz-col nzSpan="12" *ngIf="item?.finishDateTime">
          <div><b>Finish Date:</b> {{ formatDate(item?.finishDateTime) }}</div>
        </nz-col>
      </nz-row>
    
      <ng-template #actionSelect>
        <label *ngIf="item.status === 'New' || item.status === 'InTransit' || item.status === 'AssignedToDelivery'" nz-checkbox [(ngModel)]="item.isChecked"></label>
      </ng-template>
    
      <ng-template #actionOpen>
        <div nz-tooltip nzTooltipTitle="Open" [routerLink]="['/dispatch-request', item.id]">
          <i class="far fa-eye"></i>
        </div>
      </ng-template>
    </nz-card>
    
    </div>
  </div>
</nz-spin>
