<nz-page-header (nzBack)="toList()" nzBackIcon [nzTitle]="item?.name">
  <nz-page-header-extra>
    <button nz-button nzShape="round" (click)="newReturnRequest(item)">
      New return request
    </button>
    <button nz-button nzShape="round" (click)="newDispatchRequest(item)">
      New dispatch request
    </button>
    <button nz-button nzShape="round" (click)="createStock()">
      <i class="fas fa-plus"></i>
    </button>
  </nz-page-header-extra>
</nz-page-header>
<nz-card>
  <nz-tabset (nzSelectChange)="tabChanged($event)">
    <nz-tab nzTitle="Items">
      <nz-table [nzLoading]="isLoading" #stockTable nzSize="small" [nzData]="item?.items" style="min-width: 100%"
        [nzPageSize]="500" [nzScroll]="{ x: '800px' }">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Serial number</th>
            <th>Condition</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of stockTable.data">
            <td>
              <a [routerLink]="['/item', i.item.id]">{{ i.item.name }}</a>
            </td>
            <td>{{ i.item.type }}</td>
            <td>{{ i.item.serialNumber }}</td>
            <td>{{ i.isUsed ? "Used" : "New" }}</td>
            <td>{{ i.amount }}</td>
            <td></td>
          </tr>
        </tbody>
      </nz-table>
    </nz-tab>
    <nz-tab nzTitle="Consumable delivered">
      <nz-table [nzLoading]="isLoading" #deliveredConsumablesTable nzSize="small" [nzData]="deliveredConsumables"
        [nzPageSize]="500" style="min-width: 100%">
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of deliveredConsumables">
            <td>
              <a [routerLink]="['/item', i.item.id]">{{ i.item.name }}</a>
            </td>
            <td>{{ i.amount }}</td>
          </tr>
        </tbody>
      </nz-table>
    </nz-tab>

<!--  History 
 
 
      To Edit -->
    <nz-tab nzTitle="History">
      <nz-page-header>
        <nz-page-header-extra>
          <button nz-button nzType="primary" nzShape="round" (click)="createModalVisible = true;">
            <span>Add Entry</span>
          </button>
        </nz-page-header-extra>
      </nz-page-header>
      <nz-spin nzTip="Loading..." [nzSpinning]="isLoading">
        <div class="row">
          <div class="col-md-12">
            <nz-table [nzData]="history" nzSize="small">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Comment</th>
                  <th>Transporte</th>
                  <th>Valor de transporte</th>
                  <th>Documentos</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of history">
                  <td> <a (click)="openLinkModal(data.createdOn)">{{ data.createdOn }}</a></td>
                  <td>{{ data.comment }}</td>
                  <td>{{ data.transport}}</td>
                  <td>{{ data.value }}</td>
                  <td>{{ data.document?.filename }}
                    <a *ngIf="data.document?.filename" target="_blank" [href]="data.document?.googleDocLocation">
                      <i class="fas fa-download"></i>
                    </a>
                  </td>
                  <td>
                    <button nz-button nzShape="round" nzSize="small" style="margin-right: 3px;" (click)="openItem(data.id)">
                      <i class="fas fa-pen"></i>
                    </button>
                    <button nz-button nzShape="round" nzSize="small" nz-popconfirm nzPopconfirmTitle="Are you sure?"
                      (nzOnConfirm)="remove(data.id)" (nzOnCancel)="cancel()" nzPopconfirmPlacement="bottom">
                      <i style="color: red" class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </nz-spin>
    </nz-tab>
  </nz-tabset>
</nz-card>

