import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfigsItemsModifyComponent } from '../modify/modify.component';

@Component({
  selector: 'app-configs-items-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class ConfigsItemsListComponent implements OnInit {
  isLoading;

  list = [];

  constructor(private modalService: NzModalService, private apollo: Apollo) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: `no-cache`,
        query: gql`
          {
            itemCategories {
              id
              value
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.list = [...r.data.itemCategories];
        this.isLoading = false;
      });
  }

  new() {
    this.modalService
      .create({
        nzTitle: 'Add new category',
        nzFooter: null,
        nzContent: ConfigsItemsModifyComponent,
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  edit(item) {
    this.modalService
      .create({
        nzTitle: 'Edit category',
        nzFooter: null,
        nzContent: ConfigsItemsModifyComponent,
        nzData: {
          item: item,
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }
}
