import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-supplier-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.less']
})
export class SupplierModifyComponent implements OnInit {
  isLoading = false;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });
  @Input() item;

  form: FormGroup;

  constructor(
    private apollo: Apollo,
    private modalRef: NzModalRef
  ) {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [Validators.required]),
      address: new FormControl(null),
      contactPersonName: new FormControl(null),
      phoneNumber: new FormControl(null),
      email: new FormControl(null),
    })
  }

  ngOnInit(): void {
    this.item = this.nzModalData.item;
    if(this.item) this.form.patchValue(this.item);
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
        mutation action($data: CreateSupplierCommand) {
          ${this.item ? 'updateSupplier' : 'createSupplier'}(data: $data)
        }
      `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.modalRef.close('ok');
      });
  }

}
