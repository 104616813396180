import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { GraphQlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-dispatch-requests-new-mass-dispatch',
  templateUrl: './new-mass-dispatch.component.html',
  styleUrls: ['./new-mass-dispatch.component.less']
})
export class DispatchRequestsNewMassDispatchComponent implements OnInit {
  isLoading = false;

  form: FormGroup;

  warehouses = [];
  items;
  clients;

  selectedItems = [];
  selectedLocations = [];

  constructor(private apollo: Apollo, private graphQlService: GraphQlService, private router: Router, private modalRef: NzModalRef) {
    this.form = new FormGroup({
      warehouse: new FormControl(null, [Validators.required]),
      locations: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.loadWarehouses();
    this.form.get(`warehouse`).valueChanges.subscribe(() => {
      this.loadWarehouseItems();
      this.loadClients();
    });
    this.form.get(`locations`).valueChanges.subscribe(() => {
      this.selectedLocations = this.form.get(`locations`).value;
      this.selectedLocations.forEach(loc => {
        this.clients.forEach(client => {
          client.locations.forEach(clientLoc => {
            if (clientLoc.id === loc.id) loc.client = { id: client.id, name: client.name };
          })
        })
      })
    })
  }

  filterOption(inputValue: string, item: any): boolean {
    if (!inputValue) inputValue = "";
    return (item.title || "").toLowerCase().includes(inputValue.toLowerCase());
  }

  loadWarehouses() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouses {
              id
              name
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.warehouses = r.data.warehouses;
        this.isLoading = false;
      });
  }

  loadWarehouseItems() {
    if (!this.form.get(`warehouse`).value) return;
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouse(id: "${this.form.get(`warehouse`).value.id}") {
              items {
                id
                amount
                isUsed
                item {
                  id
                  name
                  serialNumber
                }
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.items = r.data.warehouse?.items.map(x => ({ ...x, key: x.id, title: x.item.name })).filter(x => x.amount > 0)
        this.isLoading = false;
      });
  }

  loadClients = () => {
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
            {
              clients(activeOnly: true) {
                id
                name
                locations {
                  id
                  name
                  isActive
                }
              }
            }
          `,
      })
      .subscribe((r: any) => {
        this.clients = r.data.clients;
        this.clients.forEach(client => client.locations = client.locations.filter(x => x.isActive === true))
      });
  }

  moveItems = (e) => {
    if (e.from === "left") e.list.forEach(item => this.selectedItems.push(item));
    else e.list.forEach(item => this.selectedItems = this.selectedItems.filter(x => x.id != item.id));
    console.log('moveItems', e, this.selectedItems)
  }

  submit = () => {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        errorPolicy: 'all',
        mutation: gql`
          mutation action($data: CreateMassDispatchRequestCommand, $items: String) {
            createMassDispatchRequest(data: $data, items: $items)
          }
      `,
        variables: {
          data: {
            warehouseId: this.form.get(`warehouse`).value['id']
          },
          items: JSON.stringify(this.selectedLocations.map(loc => ({ clientId: loc.client.id, clientLocationId: loc.id, items: JSON.stringify(loc.items.map(item => ({ warehouseItemId: item.id, amount: item.selectedAmount }))) })))
        }
      })
      .subscribe((r: any) => {
        this.isLoading = false;
        if (r.errors) {
          this.graphQlService.displayErrors(r.errors);
          return;
        }
        this.modalRef.close('ok');
        this.router.navigateByUrl(
          `/dispatch-requests`
        );
      });
  }

  getLocationItemAmount = (location, item) => {
    if (!this.selectedLocations) return 0;
    var l = this.selectedLocations.find(x => x.id === location.id);
    if (!l || !l.items) return 0;
    var i = l.items.find(x => x.id === item.id);
    if (!i) return 0;
    return i.selectedAmount;
  }

  setLocationItemAmount = (location, item, amount) => {
    if (!amount) amount = 0;
    var l = this.selectedLocations.find(x => x.id === location.id);
    if (!l.items) l.items = [];
    var i = l.items.find(x => x.id === item.id);
    if (!i) {
      l.items.push({ ...item, selectedAmount: 0 });
      var i = l.items.find(x => x.id === item.id);
    }

    if (amount > i.selectedAmount) {
      this.selectedItems.find(x => x.id === item.id).amount -= (amount - i.selectedAmount)
    }
    else if (amount < i.selectedAmount) {
      this.selectedItems.find(x => x.id === item.id).amount += (i.selectedAmount - amount)
    }
    i.selectedAmount = amount;
  }

}
