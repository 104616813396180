<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Low stock notification
      </div>
      <div nz-col nzSpan="8" style="padding-top: 2px; text-align: center">
        <nz-switch
          [ngModel]="form.get('lowStockAmount').value === null ? false : true"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="toggleLowStockEnabled()"
        ></nz-switch>
      </div>
      <div nz-col nzSpan="8" *ngIf="lowStockAmountEnabled === true">
        <nz-input-number
          formControlName="lowStockAmount"
          [nzMin]="1"
          [nzStep]="1"
          style="width: 100px"
        ></nz-input-number>
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">
        {{ item ? "Update" : "Create" }}
      </button>
    </div>
  </form>
</nz-spin>
