<nz-spin [nzSpinning]="isLoading">
  <nz-page-header (nzBack)="toList()" nzBackIcon [nzTitle]="item?.name">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="new()">
        Add new location
      </button>
    </nz-page-header-extra>
  </nz-page-header>

  <app-clients-locations-list
    *ngIf="isLoading === false"
    [clientId]="id"
  ></app-clients-locations-list>
</nz-spin>
