import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { GraphQlService } from 'src/app/services/graphql.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-items-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class ItemsViewComponent implements OnInit {
  apiUrl = environment.apiUrl;

  isLoading;

  id;
  item;

  suppliers;

  constructor(
    private location: Location,
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private graphQlService: GraphQlService,
    private http: HttpClient,
    private messageService: NzMessageService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((r) => {
      this.id = r['id'];
      this.load();
    });
  }

  toList = () => this.location.back();

  load() {
    this.isLoading = true;
    this.graphQlService.get(`{  item(id:"${this.id}") { name icon
      atWarehouses { id amount isUsed warehouse { id name } } 
      atLocations { id amount isUsed location { id name client { id name } } }
    } }`).subscribe((r: any) => {
      this.item = r.data.item;
      this.item.atLocations = this.item.atLocations.filter(
        (x) => x.amount > 0
      );
      this.isLoading = false;
    })
  }

  loadSuppliers() {
    this.isLoading = true;
    this.graphQlService.get(`{ item(id:"${this.id}") { suppliers { price supplier { id name } } } }`).subscribe((r: any) => {
      this.suppliers = r.data.item.suppliers;
      this.isLoading = false;
    })
  }

  tabChanged(event) {
    if (event.index === 1) this.loadSuppliers();
  }

  uploadDocument = (item) => {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('targetType', `item`);
    formData.append('targetId', this.id);

    const req = new HttpRequest('POST', item.action!, formData, {
      headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem(`jwtToken`) }),
      reportProgress: true,
      withCredentials: true,
    });

    return this.http.request(req).subscribe(
      (event: HttpEvent<{}>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
          this.messageService.success('Image uploaded');
          this.load();
        }
      },
      (err) => {
        item.onError!(err, item.file!);
        console.log('err', err)
        if(err.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        this.messageService.error('Network error');
        this.isLoading = false;
      }
    );
  };
}
