import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsListComponent } from './list/list.component';
import { ClientsModifyComponent } from './modify/modify.component';
import { SharedModule } from '../shared.module';
import { ClientsViewComponent } from './view/view.component';
import { ClientsLocationsModule } from '../clients-locations/clients-locations.module';

@NgModule({
  declarations: [
    ClientsListComponent,
    ClientsModifyComponent,
    ClientsViewComponent,
  ],
  imports: [CommonModule, SharedModule, ClientsLocationsModule],
})
export class ClientsModule {}
