<nz-spin [nzSpinning]="isLoading">
    <nz-page-header nzTitle="Suppliers">
        <nz-page-header-extra>
            <button nz-button nzShape="round" (click)="newSupplier()">
                Add new supplier
            </button>
            <!-- <button nz-button nzShape="round" (click)="filterModalVisible = true">
          <i class="fas fa-search"></i>
        </button> -->
        </nz-page-header-extra>
    </nz-page-header>

    <nz-table #basicTable nzSize="small" [nzData]="displayList" style="min-width: 100%" [nzScroll]="{ x: '800px' }"
        [nzPageSize]="30" nzShowSizeChanger>
        <thead>
            <th *ngFor="let column of tableColumns; index as i">{{ column.label }}</th>
            <th></th>
        </thead>
        <tbody>
            <tr *ngFor="let item of basicTable.data">
                <td *ngFor="let column of tableColumns; index as i">{{ item[column.dataIndex] }}</td>
                <td>
                    <a style="margin-right: 3px" [routerLink]="['/supplier', item.id]" routerLinkActive="active">
                        <button nz-button nzShape="round" nzSize="small">
                            <i class="far fa-eye"></i>
                        </button>
                    </a>
                    <button nz-button nzShape="round" nzSize="small" style="margin-right: 3px" (click)="editSupplier(item)">
                        <i class="fas fa-cogs"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </nz-table>
</nz-spin>