import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-configs-items-kits-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.less'],
})
export class ConfigsItemsKitsModifyComponent implements OnInit {


  @Input() id;
  @Input() items = [
    {
      amount: 1,
      name: 'Item 1',
    },
    {
      amount: 2,
      name: 'Item 2',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
