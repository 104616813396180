import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { uniq, sum } from 'lodash';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-deliveries-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class DeliveriesListComponent implements OnInit {
  isLoading: boolean;
  list: any[];
  statusSearch: string[];
  filterVisible: boolean = false;
  clientNameSearch: string;
  clientLocationNameSearch: string;

  constructor(
    private apollo: Apollo,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((r) => {
      this.statusSearch = !r.statusSearch ? ['New'] : r.statusSearch;
      this.clientNameSearch = r.clientNameSearch;
      this.clientLocationNameSearch = r.clientLocationNameSearch;

      this.load();
    });
  }

  load(): void {
    this.isLoading = true;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        statusSearch: this.statusSearch,
        clientNameSearch: this.clientNameSearch,
        clientLocationNameSearch: this.clientLocationNameSearch,
      },
      queryParamsHandling: 'merge',
    });

    let query = `
      {
        deliveries(status: "${this.statusSearch}") {
          id
          status
          createdOn
          startDateTime
          finishDateTime
          dispatches {
            id
            client {
              id
              name
            }
            clientLocation {
              id
              name
            }
            items {
              amount
            }
          }
        }
      }
    `;

    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql(query),
      })
      .subscribe((r: any) => {
        this.list = r.data.deliveries;
        this.list.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());

        console.log('deliveries', this.list);
        this.list.forEach((item) => {
          item.clients = uniq(
            item.dispatches.map((x) => x.client?.name)
          ).join(', ');
          item.clientsLocations = uniq(
            item.dispatches.map((x) => x.clientLocation?.name)
          ).join(', ');
          item.itemsCount = sum(
            item.dispatches.map((x) => sum(x.items.map((y) => y.amount)))
          );
        });

        if (this.clientNameSearch) {
          this.list = this.list.filter((item) =>
            item.clients.includes(this.clientNameSearch)
          );
        }

        if (this.clientLocationNameSearch) {
          this.list = this.list.filter((item) =>
            item.clientsLocations.includes(this.clientLocationNameSearch)
          );
        }

        this.isLoading = false;
      });
  }

  filterData(): void {
    this.clientLocationNameSearch = '';
    this.clientNameSearch = '';
    this.load();
  }
  /*  searchByClientName(): void {
     this.clientLocationNameSearch = '';
     this.load();
   }
 
   searchByClientLocationName(): void {
     this.clientNameSearch = '';
     this.load();
   } */
  toggleFilterVisible(): void {
    this.filterVisible = !this.filterVisible;
    if (!this.filterVisible) {

    }
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In Progress':
        return 'orange';
      case 'InTransit':
        return 'red';
      case 'New':
        return 'blue';
      default:
        return 'default';
    }
  }
  
  formatDate(dateTimeString): string {
    const date = new Date(dateTimeString);
    return format(date, 'dd/MM/yyyy');
  }
}
