<nz-page-header (nzBack)="toList()" nzBackIcon nzTitle="Item: {{ item?.name }}">
</nz-page-header>

<nz-upload nzType="drag" [nzMultiple]="false" nzAction="{{apiUrl}}/api/images" [nzCustomRequest]="uploadDocument"
  nzName="avatar" nzListType="picture-card" [nzShowUploadList]="false">
  <p class="ant-upload-drag-icon">
    <i nz-icon nzType="inbox"></i>
  </p>
  <p class="ant-upload-text">Click or drag file to this area to upload</p>
  <div *ngIf="item && item.icon">
    <hr />
    Current icon: (300x300)<br />
    <img style="width: 200px; height: 200px;" src="{{ apiUrl }}{{ item.icon }}" class="avatar" />
  </div>
</nz-upload>

<nz-tabset (nzSelectChange)="tabChanged($event)">
  <nz-tab nzTitle="Stock">
    <h3>Stock at warehouses</h3>
    <nz-table #warehouseStockTable nzSize="small" [nzData]="item?.atWarehouses" style="min-width: 100%;"
      [nzScroll]="{ x: '800px' }">
      <thead>
        <tr>
          <th>Warehouse</th>
          <th>Condition</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of warehouseStockTable.data">
          <td>
            <a [routerLink]="['/warehouse', i.warehouse.id]">{{
              i.warehouse.name
              }}</a>
          </td>
          <td>{{ i.isUsed && i.isUsed === true ? "Used" : "New" }}</td>
          <td>{{ i.amount }}</td>
        </tr>
      </tbody>
    </nz-table>

    <h3>Stock at clients</h3>
    <nz-table #locationStockTable nzSize="small" [nzData]="item?.atLocations" style="min-width: 100%;"
      [nzScroll]="{ x: '800px' }">
      <thead>
        <tr>
          <th>Client</th>
          <th>Location</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of locationStockTable.data">
          <td>
            <a [routerLink]="['/client', i.location.client.id]">{{
              i.location.client.name
              }}</a>
          </td>
          <td>
            <a [routerLink]="['/location', i.location.id]">{{ i.location.name }}</a>
          </td>
          <td>{{ i.amount }}</td>
          <td>
            <!-- <button
          nz-button
          nzShape="round"
          nzSize="small"
          nz-tooltip
          nzTooltipTitle="Dispatch"
        >
          <i class="fas fa-clipboard-list"></i>
        </button> -->
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
  <nz-tab nzTitle="Suppliers">
    <nz-table #suppliersTable nzSize="small" [nzData]="suppliers" style="min-width: 100%;" [nzScroll]="{ x: '800px' }">
      <thead>
        <tr>
          <th>Name</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of suppliersTable.data">
          <td>
            <a [routerLink]="['/supplier', i.supplier.id]">{{
              i.supplier.name
              }}</a>
          </td>
          <td>{{ i.price }}</td>
        </tr>
      </tbody>
    </nz-table>
  </nz-tab>
</nz-tabset>