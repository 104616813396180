import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { DeliveriesListComponent } from './list/list.component';
import { DeliveriesViewComponent } from './view/view.component';
import { DeliveriesModifyComponent } from './modify/modify.component';

export const routes: Routes = [
  {
    path: 'deliveries',
    component: DeliveriesListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'delivery/:id',
    component: DeliveriesViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'delivery/modify/:id',
    component: DeliveriesModifyComponent,
    canActivate: [AuthGuard],
  },
];
