import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsListComponent } from './list/list.component';
import { ItemsViewComponent } from './view/view.component';
import { SharedModule } from '../shared.module';
import { ItemsModifyComponent } from './modify/modify.component';

@NgModule({
  declarations: [ItemsListComponent, ItemsViewComponent, ItemsModifyComponent],
  imports: [CommonModule, SharedModule],
})
export class ItemsModule {}
