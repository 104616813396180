<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Warehouse
      </div>
      <div nz-col nzSpan="12">
        <nz-select style="width: 100%" formControlName="targetWarehouseId">
          <nz-option
            *ngFor="let i of warehouses"
            [nzValue]="i.id"
            [nzLabel]="i.name"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="12">
        <h4>Items</h4>
      </div>
      <div nz-col nzSpan="12" style="text-align: right">
        <button
          type="button"
          nz-button
          nzShape="round"
          nzSize="small"
          (click)="returnAll()"
        >
          Return all
        </button>
      </div>
    </div>
    <nz-list nzItemLayout="horizontal" nzBordered style="margin-bottom: 5px">
      <nz-list-item *ngFor="let i of items">
        <ng-container>
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              {{ i.item.name }} ({{ i.isUsed ? "Used" : "New" }}) (Current
              amount: {{ i.amount }})
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          <div nz-row>
            <div
              nz-col
              nzSpan="12"
              style="text-align: center"
              *ngIf="!i.isUsed"
            >
              <span>New: </span><br />
              <nz-input-number
                [(ngModel)]="i.newAmount"
                [ngModelOptions]="{ standalone: true }"
                [nzMin]="0"
                [nzMax]="i.amount"
                [nzStep]="1"
              ></nz-input-number>
            </div>
            <div nz-col nzSpan="12" style="text-align: center">
              <span>Used: </span><br />
              <nz-input-number
                [(ngModel)]="i.usedAmount"
                [ngModelOptions]="{ standalone: true }"
                [nzMin]="0"
                [nzMax]="i.amount"
                [nzStep]="1"
              ></nz-input-number>
            </div>
          </div>
        </ng-container>
      </nz-list-item>
    </nz-list>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">Submit</button>
    </div>
  </form>
</nz-spin>
