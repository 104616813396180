import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { ClientsListComponent } from './list/list.component';
import { ClientsViewComponent } from './view/view.component';

export const routes: Routes = [
  {
    path: 'clients',
    component: ClientsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'client/:id',
    component: ClientsViewComponent,
    canActivate: [AuthGuard],
  },
];
