import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { WarehouseNewComponent } from '../new/new.component';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class WarehouseListComponent implements OnInit {
  isLoading;

  list = [];

  constructor(private modalService: NzModalService, private apollo: Apollo) {}

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouses {
              id
              name
              address
              region
              postalCode geoLocationLat geoLocationLong
              stockValue
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.list = r.data.warehouses;
        this.isLoading = false;
      });
  }

  newWarehouse() {
    this.modalService
      .create({
        nzTitle: 'Add new warehouse',
        nzFooter: null,
        nzContent: WarehouseNewComponent,
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  editWarehouse(item) {
    this.modalService
      .create({
        nzTitle: 'Edit warehouse',
        nzFooter: null,
        nzContent: WarehouseNewComponent,
        nzData: {
          item: item,
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }
}
