<nz-spin [nzSpinning]="isLoading">
    <form nz-form [formGroup]="form" (ngSubmit)="submit()">
      <div nz-row nzGutter="16" style="margin-bottom: 10px">
        <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
          Item type
        </div>
        <div nz-col nzSpan="12">
          <nz-select
            style="width: 100%"
            [(ngModel)]="itemType"
            (ngModelChange)="load()"
            [ngModelOptions]="{ standalone: true }"
            nzShowSearch
          >
            <nz-option nzValue="Consumable" nzLabel="Consumable"></nz-option>
            <nz-option nzValue="Equipment" nzLabel="Equipment"></nz-option>
            <nz-option nzValue="Asset" nzLabel="Asset"></nz-option>
          </nz-select>
        </div>
      </div>
  
      <div *ngIf="itemType">
      <div nz-row nzGutter="16" style="margin-bottom: 10px">
        <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
          Item
        </div>
        <div nz-col nzSpan="12">
          <nz-select style="width: 100%" formControlName="itemId" nzShowSearch>
            <nz-option
              *ngFor="let i of items"
              [nzValue]="i.id"
              nzCustomContent
              nzLabel="{{ i.name }} {{
                i.type === 'Asset' ? i.serialNumber : ''
              }}"
            >
              {{ i.name }}
              <nz-tag
                nzColor="default"
                style="float: right"
                *ngIf="i.serialNumber"
                >{{ i.serialNumber }}</nz-tag
              >
            </nz-option>
          </nz-select>
        </div>
      </div>
  
      <div
        nz-row
        nzGutter="16"
        style="margin-bottom: 10px"
        *ngIf="itemType !== 'Asset'"
      >
        <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
          Price
        </div>
        <div nz-col nzSpan="12">
          <nz-input-number
            style="width: 100%"
            formControlName="price"
            nzPlaceHolder="Price"
            [nzMin]="0"
          ></nz-input-number>
        </div>
      </div>
    </div>
  
      <div style="text-align: center">
        <button nz-button nzType="primary" nzShape="round">Create</button>
      </div>
    </form>
  </nz-spin>
  