import { Component, inject, Input, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-warehouse-stock-logs',
  templateUrl: './stock-logs.component.html',
  styleUrls: ['./stock-logs.component.less'],
})
export class WarehouseStockLogsComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() id;
  @Input() item;
  list = [];

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.id = this.nzModalData.id;
    this.item = this.nzModalData.item;

    this.load();
  }

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        query: gql`
          {
            warehouseItem(id: "${this.id}") {
              logs {
                actionType
                payload
                createdOn
                user { username }
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.list = r.data.warehouseItem.logs.map((x) => ({
          ...x,
          payload: JSON.parse(x.payload),
        }));
        this.isLoading = false;
      });
  }
}
