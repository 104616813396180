import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-clients-locations-return-form',
  templateUrl: './return-form.component.html',
  styleUrls: ['./return-form.component.less'],
})
export class ReturnFormComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() items = [];
  @Input() clientId;
  @Input() clientLocationId;

  warehouses = [];

  form: FormGroup;

  constructor(
    private apollo: Apollo,
    private messageService: NzMessageService,
    private modalRef: NzModalRef,
    private router: Router
  ) {
    this.form = new FormGroup({
      targetWarehouseId: new FormControl(null, [Validators.required]),
      clientId: new FormControl(null, [Validators.required]),
      clientLocationId: new FormControl(null, [Validators.required]),
      items: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.clientId = this.nzModalData.clientId;
    this.clientLocationId = this.nzModalData.clientLocationId;
    this.items = this.nzModalData.items;

    this.form.get(`clientId`).setValue(this.clientId);
    this.form.get(`clientLocationId`).setValue(this.clientLocationId);
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouses {
              id
              name
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.warehouses = r.data.warehouses;
        this.isLoading = false;
      });
  }

  submit() {
    this.items.forEach((item) => {
      if (item.usedAmount + item.newAmount > item.amount) {
        this.messageService.error(
          `Item ${item.item.name} (${
            item.isUsed ? 'Used' : 'New'
          }) don't have enough items!`
        );
        return;
      }
    });
    console.log('test', this.form);
    this.form.get(`items`).setValue(JSON.stringify(this.items));
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action(
            $data: CreateClientLocationReturnItemsDispatchRequestCommand
          ) {
            createClientLocationReturnItemsDispatchRequest(data: $data)
          }
        `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe((r: any) => {
        this.isLoading = false;
        this.modalRef.close('ok');
        this.router.navigateByUrl(
          `/dispatch-request/${r.data.createClientLocationReturnItemsDispatchRequest}`
        );
      });
  }

  returnAll() {
    this.items.forEach((item) => {
      item.usedAmount = item.amount;
      item.newAmount = 0;
    });
  }
}
