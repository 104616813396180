import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/services/auth.service';
import { GraphQlService } from 'src/app/services/graphql.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  isLoading = false;

  email;
  password;

  list;

  constructor(
    private authService: AuthService,
    private messageService: NzMessageService,
    private httpClient: HttpClient,
    private router: Router,
    private graphQlService: GraphQlService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(r => {
      if (r.uid && r.jwt) this.loadUser(r.jwt, r.uid);
    })
  }

  login() {
    if (!environment.platform || environment.platform === "web") this.loginLocal();
    else this.loginSso();
  }

  loginLocal = () => {
    this.isLoading = true;
    this.httpClient
      .post<any>(
        `${this.authService.apiUrl}/api/auth/basic/login`,
        JSON.stringify({ email: this.email, password: this.password }),
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8;',
          },
        }
      )
      .subscribe(
        (r) => this.loadUser(r.jwtToken, r.userId),
        (e) => {
          if (e.error.Message) this.messageService.error(e.error.Message);
          else this.messageService.error(JSON.stringify(e));
          this.isLoading = false;
        }
      );
  }

  loginSso = () => {
    this.isLoading = true;
    this.httpClient
      .post<any>(
        `https://leap-app.eu/api/auth/login`,
        JSON.stringify({ email: this.email, password: this.password }),
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8;',
          },
        }
      )
      .subscribe(
        (r) => {
          this.list = r;
          if (this.list.length === 1) this.setInstance(this.list[0]);
          this.isLoading = false;
        },
        (e) => {
          if (e.status === 404) this.messageService.error(`Account not found!`);
          this.isLoading = false;
        }
      );
  }

  loadUser = (jwt, uid) => {
    localStorage.setItem(`jwtToken`, jwt);
    localStorage.setItem(`userId`, uid);
    this.graphQlService
      .get(`{ user(id:"${uid}"){ roles { symbol } } }`)
      .subscribe((r: any) => {
        localStorage.setItem(
          `roles`,
          JSON.stringify(
            r.data.user.roles.map((x) => x.symbol.toLowerCase())
          )
        );
        this.isLoading = false;
        this.messageService.success(`Welcome back!`);
        this.authService.isLoggedIn.emit(true);
        this.router.navigateByUrl('/');
      });
  }

  setInstance = (item) => {
    this.authService.apiUrlChanged.emit(item.instance.url);
    this.loadUser(item.jwtToken, item.userId);
  }
}
