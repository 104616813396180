import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-create-stock-temporary',
  templateUrl: './create-stock-temporary.component.html',
  styleUrls: ['./create-stock-temporary.component.less'],
})
export class CreateStockTemporaryComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  itemType;
  @Input() clientLocationId;
  items = [];

  form: FormGroup;

  constructor(
    private apollo: Apollo,
    private modalRef: NzModalRef,
    private notificationService: NzNotificationService
  ) {
    this.form = new FormGroup({
      clientLocationId: new FormControl(null, [Validators.required]),
      itemId: new FormControl(null, [Validators.required]),
      isUsed: new FormControl(null, [Validators.required]),
      amount: new FormControl(1, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.clientLocationId = this.nzModalData.clientLocationId;
    this.form.get(`clientLocationId`).setValue(this.clientLocationId);
    this.load();
  }

  load() {
    if (!this.itemType) return;
    this.form.get(`amount`).setValue(1);
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            items(type: "${this.itemType}") {
              id
              name
              type
              serialNumber
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.items = r.data.items.filter(
          (x) => x.type === 'Equipment' || x.type === 'Asset'
        );
        this.isLoading = false;
      });
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: CreateClientLocationItemCommand) {
            createClientLocationItem(data: $data)
          }
        `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.modalRef.close('ok');
      });
  }

  getItemType = (id) =>
    this.items.find((x) => x.id === id)
      ? this.items.find((x) => x.id === id).type
      : '';
}
