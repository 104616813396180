  <nz-tabset [nzTabBarExtraContent]="extraTemplate">
    <nz-tab nzTitle="Step 1">
      <form nz-form [formGroup]="form" (ngSubmit)="submit()">
        <div nz-row nzGutter="16" style="margin-bottom: 10px">
          <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
            Warehouse
          </div>
          <div nz-col nzSpan="12">
            <nz-select style="width: 100%" formControlName="warehouse">
              <nz-option *ngFor="let i of warehouses" [nzValue]="i" [nzLabel]="i.name"></nz-option>
            </nz-select>
          </div>
        </div>

        <div nz-row nzGutter="12" *ngIf="items && clients">
          <div nz-col nzXs="24" nzSm="24">
            <h3>Locations</h3>
            <nz-select style="width: 100%" nzAllowClear nzShowSearch nzMode="multiple" formControlName="locations">
              <nz-option-group [nzLabel]="client.name" *ngFor="let client of clients">
                <nz-option *ngFor="let location of client.locations" [nzValue]="location" [nzLabel]="location.name">
                </nz-option>
              </nz-option-group>
            </nz-select>
          </div>
          <div nz-col nzXs="24" nzSm="24">
            <h3>Items</h3>
            <nz-transfer [nzDataSource]="items" [nzTitles]="['Available', 'Selected']" [nzRender]="render" nzShowSearch
              [nzFilterOption]="filterOption" [nzListStyle]="{ 'width.%': 100, 'height.px': 450 }"
              (nzChange)="moveItems($event)">
              <ng-template #render let-item>
                <nz-tag>{{ item.isUsed ? "[U]" : "[N]" }} </nz-tag>
                {{ item.item.name }}
                <nz-tag *ngIf="item.item.serialNumber">{{
                item.item.serialNumber
              }}</nz-tag>
                <nz-tag *ngIf="!item.item.serialNumber">In stock: {{ item.amount }}</nz-tag>
              </ng-template>
            </nz-transfer>
          </div>
        </div>
      </form>

    </nz-tab>
    <nz-tab nzTitle="Step 2">
      <nz-collapse>
        <nz-collapse-panel *ngFor="let location of selectedLocations"
          nzHeader="{{ location.client.name }} -> {{location.name}}" [nzActive]="true">
          <div nz-row nzGutter="12" *ngFor="let item of selectedItems" style="margin-top: 2px">
            <div nz-col nzSpan="12" style="text-align: right; padding-top: 3px">
              <nz-tag>{{ item.isUsed ? "[U]" : "[N]" }} </nz-tag>
              {{ item.title }}
              <nz-tag *ngIf="item.item.serialNumber">
                {{ item.item.serialNumber }}
              </nz-tag>
              <nz-tag *ngIf="!item.item.serialNumber">(Available: {{ item.amount }})</nz-tag>
            </div>
            <div nz-col nzSpan="12">
              <nz-input-number [ngModel]="getLocationItemAmount(location, item)" [nzMin]="0" style="width: 100%;"
                [nzStep]="1" (ngModelChange)="setLocationItemAmount(location, item, $event)">
              </nz-input-number>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-tab>
  </nz-tabset>
  <ng-template #extraTemplate>
    <button nz-button (click)="submit()" [disabled]="isLoading">Submit</button>
  </ng-template>
