<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Items">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="newItem()">
        Add new item
      </button>
    <button nz-button nzShape="round" (click)="filterVisiblebtn()">
        <i class="fas fa-search"></i>
      </button> 

    </nz-page-header-extra>
    <nz-page-header-content>
     <!--  <div nz-row style="text-align: center; margin-top: 15px">
        <div nz-col nzSpan="8">Field</div>
        <div nz-col nzSpan="8">Search</div>
      </div> -->
      <div nz-row *ngIf="filterVisible">
        <div nz-col nzSpan="12" *ngFor="let field of filterableColumns" style="text-align: center; margin-top: 5px; ">
          <input nz-input placeholder="Search for {{ field.label }}"  [(ngModel)]="field.searchValue" />
        </div>
        <div nz-col nzSpan="24" style=" margin-top: 15px">
          <button nz-button nzType="primary" style="float: right;" nzShape="round" (click)="filterData()">
            Search  <i style="margin-left: 5px;" class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </nz-page-header-content>
  </nz-page-header>


  <nz-card  >
    <div nz-row>
      <div nz-col nzSpan="20">
        <div>
          <div style="display: inline; margin-right: 10px">Type:</div>
          <div style="display: inline">
            <button type="button" nz-button nzShape="round" nzSize="small" (click)="filterByType('Consumable')"
              style="margin-right: 3px">
              Consumables
            </button>
            <button type="button" nz-button nzShape="round" nzSize="small" (click)="filterByType('Equipment')"
              style="margin-right: 3px">
              Equipments
            </button>
            <button type="button" nz-button nzShape="round" nzSize="small" (click)="filterByType('Asset')"
              style="margin-right: 3px">
              Assets
            </button>
            <button
            type="button" nz-button nzShape="round" nzSize="small" (click)="filterByType('All')"
            style="margin-right: 3px"
          >
            All
          </button>
            <!-- <nz-select
              [(ngModel)]="itemType"
              style="width: 150px"
              (ngModelChange)="load()"
            >
              <nz-option
                nzValue="Consumable"
                nzLabel="Consumable"
              ></nz-option>
              <nz-option nzValue="Equipment" nzLabel="Equipment"></nz-option>
              <nz-option nzValue="Asset" nzLabel="Asset"></nz-option>
            </nz-select> -->
          </div>
        </div>
      </div>
      <div nz-col nzSpan="4">
        <nz-select [(ngModel)]="pageSize" style="width: 150px; margin-left: 5px;">
          <nz-option nzValue="20" nzLabel="20  / page"></nz-option>
          <nz-option nzValue="30" nzLabel="30  / page"></nz-option>
          <nz-option nzValue="40" nzLabel="40  / page"></nz-option>
          <nz-option nzValue="50" nzLabel="50  / page"></nz-option>
          <nz-option nzValue="100" nzLabel="100  / page"></nz-option>
          <nz-option nzValue="500" nzLabel="500  / page"></nz-option>
          <!-- adicione mais opções conforme necessário -->
        </nz-select>

      </div>
    </div>
  </nz-card >

  <nz-table #basicTable nzSize="small" [nzData]="displayItems" style="min-width: 100%" [nzScroll]="{ x: '800px' }"
  [(nzPageSize)]="pageSize">
    <thead>
      <tr>
        <ng-container *ngFor="let column of columns; index as i">
          <th nzCustomFilter [nzSortOrder]="column.sortOrder" [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections" *ngIf="
              !column.filterItemType ||
              (column.filterItemType &&
                column.filterItemType.includes(itemType))
            ">
            {{ column.label }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of basicTable.data">
        <td>{{ item.name }}</td>
        <td *ngIf="itemType === 'Asset'">{{ item.serialNumber }}</td>
        <td>{{ item.referenceNumber }}</td>
        <td>{{ item.brand }}</td>
        <td>€{{ item.price }}</td>
        <td>{{ item.category?.value }}</td>
        <td *ngIf="itemType === 'Consumable' || itemType === 'Equipment'">
          {{ item.amount }}
        </td>
        <td>
          <a style="margin-right: 3px" [routerLink]="['/item', item.id]" routerLinkActive="active">
            <button nz-button nzShape="round" nzSize="small">
              <i class="far fa-eye"></i>
            </button>
          </a>
          <button nz-button nzShape="round" nzSize="small" style="margin-right: 3px" (click)="editItem(item)">
            <i class="fas fa-cogs"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>

<!-- <nz-modal [(nzVisible)]="filterModalVisible" nzTitle="Filters/searches" (nzOnCancel)="filterModalVisible = false"
  (nzOnOk)="filterData()" nzWidth="80%">
  <div nz-row style="text-align: center">
    <div nz-col [nzSpan]="8">Field</div>
    <div nz-col [nzSpan]="8">Search</div>
  </div>
  <div nz-row *ngFor="let field of filterableColumns" style="text-align: center; margin-top: 15px">
    <div nz-col [nzSpan]="8">{{ field.label }}</div>
    <div nz-col [nzSpan]="8">
      <input nz-input placeholder="Search value" style="width: 80%" [(ngModel)]="field.searchValue" />
    </div>
  </div>
</nz-modal> -->