import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { environment } from 'src/environments/environment';

export function createNamedApollo(
  httpLink: HttpLink,
): Record<string, ApolloClientOptions<any>> {
  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('jwtToken');
    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });
  const error = onError(({ graphQLErrors, networkError }) => {
    console.log('test', graphQLErrors);
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      if (
        (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code === 'authorization') ||
        graphQLErrors[0].message.includes(
          `You are not authorized to run this query`
        )
      ) {
        console.log('redirect?')
        localStorage.clear();
        window.location.assign(`/login`);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      localStorage.clear();
      window.location.assign(`/login`);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  });
  return {
    common: {
      name: 'common',
      link: ApolloLink.from([
        error,
        auth,
        httpLink.create({
          uri: `${environment.apiUrl}/api/graphql`,
        }),
      ]),

      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createNamedApollo,
    },
  ],
})
export class GraphQLModule { }
