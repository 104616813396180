import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { ItemsListComponent } from './list/list.component';
import { ItemsViewComponent } from './view/view.component';

export const routes: Routes = [
  {
    path: 'items',
    component: ItemsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item/:id',
    component: ItemsViewComponent,
    canActivate: [AuthGuard],
  },
];
