import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { GraphQlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-dispatch-requests-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.less'],
})
export class DispatchRequestsNewComponent implements OnInit {
  isLoading;

  warehouses = [];
  items = [];
  selectedItems = [];

  clients = [];

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() clientId;
  @Input() clientLocationId;

  @Input() item;

  form: FormGroup;

  newItem;
  currentWarehouseId;

  constructor(
    private apollo: Apollo,
    private messageService: NzMessageService,
    private modalRef: NzModalRef,
    private graphQlService: GraphQlService,
    private router: Router
  ) {
    this.form = new FormGroup({
      warehouseId: new FormControl(null, [Validators.required]),
      clientId: new FormControl(null, [Validators.required]),
      clientLocationId: new FormControl(null, [Validators.required]),
      items: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.clientId = this.nzModalData.clientId;
    this.clientLocationId = this.nzModalData.clientLocationId;

    this.clearNewItem();
    this.form.get(`clientId`).setValue(this.clientId);
    this.form.get(`clientLocationId`).setValue(this.clientLocationId);
    if (!this.clientLocationId) {
      this.apollo
        .use(`common`)
        .query({
          fetchPolicy: 'no-cache',
          query: gql`
            {
              clients(activeOnly: true) {
                id
                name
                isActive
                locations {
                  id
                  name
                  isActive
                }
              }
            }
          `,
        })
        .subscribe((r: any) => {
          this.clients = r.data.clients;
          this.clients.forEach(client => client.locations = client.locations.filter(x => x.isActive === true))
        });
    }
    this.loadWarehouses();
  }

  loadWarehouses() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouses {
              id
              name
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.warehouses = r.data.warehouses;
        this.isLoading = false;
      });
  }

  loadWarehouseItems(id) {
    if (this.currentWarehouseId === id) return;
    this.currentWarehouseId = id;
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            warehouse(id: "${id}") {
              items {
                id
                amount
                isUsed
                item {
                  id
                  name
                  serialNumber
                }
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.items = r.data.warehouse?.items;
        this.selectedItems = [];
        this.clearNewItem();
        this.form.get(`warehouseId`).setValue(id, { emitEvent: false });
        this.isLoading = false;
      });
  }

  addItem() {
    var item = this.items.find((x) => x.id == this.newItem.itemId);
    if (item.amount < this.newItem.amount) {
      this.messageService.error(`Not enough items in stock!`);
      return;
    }
    this.selectedItems.push({
      warehouseItemId: this.newItem.itemId,
      name: item.item.name,
      isUsed: item.isUsed,
      amount: this.newItem.amount,
    });
    this.clearNewItem();
  }

  removeItem(id) {
    this.selectedItems = this.selectedItems.filter(
      (x) => x.warehouseItemId != id
    );
  }

  submit() {
    this.form.get(`items`).setValue(JSON.stringify(this.selectedItems));
    console.log('submit', this.form);
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        errorPolicy: 'all',
        mutation: gql`
        mutation action($data: CreateDispatchRequestCommand) {
          ${this.item ? 'updateDispatchRequest' : 'createDispatchRequest'
          }(data: $data)
        }
      `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe((r: any) => {
        this.isLoading = false;
        if (r.errors) {
          this.graphQlService.displayErrors(r.errors);
          return;
        }
        this.modalRef.close('ok');
        this.router.navigateByUrl(
          `/dispatch-request/${r.data.createDispatchRequest}`
        );
      });
  }

  isNotSelected = (value) =>
    this.selectedItems.map((x) => x.warehouseItemId).indexOf(value) === -1;

  clearNewItem = () =>
  (this.newItem = {
    itemId: undefined,
    amount: 1,
  });

  getClientLocations = (clientId) => {
    if (!clientId) return [];
    return this.clients.find((x) => x.id === clientId).locations;
  };
}
