import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GraphQlService } from 'src/app/services/graphql.service';
import { SupplierItemAddPriceComponent } from '../items/add-price/add-price.component';
import { SupplierItemPriceHistoryComponent } from '../items/price-history/price-history.component';

@Component({
  selector: 'app-supplier-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less']
})
export class SupplierViewComponent implements OnInit {
  isLoading;

  id;
  item;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private graphQlService: GraphQlService, private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((r) => {
      this.id = r['id'];
      this.load();
    });
  }

  toList = () => this.location.back();

  load() {
    this.isLoading = true;
    this.graphQlService.get(`{ supplier(id: "${this.id}") { name contactPersonName address email phoneNumber items { price history item { id name } } } }`)
      .subscribe((r: any) => {
        this.item = r.data.supplier;
        this.isLoading = false;
      });
  }

  newPrice() {
    this.modalService
      .create({
        nzTitle: 'Add new item price',
        nzFooter: null,
        nzContent: SupplierItemAddPriceComponent,
        nzData: {
          supplierId: this.id
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  showPriceHistory(item) {
    this.modalService
    .create({
      nzTitle: 'Item price history',
      nzFooter: null,
      nzContent: SupplierItemPriceHistoryComponent,
      nzData: {
        history: JSON.parse(item.history)
      },
      nzWidth: '80%',
    })
  }

}
