<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Name
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="name" placeholder="Name" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Status
      </div>
      <div nz-col nzSpan="12">
        <nz-switch formControlName="isActive" nzCheckedChildren="Active" nzUnCheckedChildren="Inactive"></nz-switch>
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">
        {{ item ? "Update" : "Create" }}
      </button>
    </div>
  </form>
</nz-spin>