import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ItemsModifyComponent } from '../modify/modify.component';

import { sum } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-items-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class ItemsListComponent implements OnInit {
  isLoading;

  items = [];
  displayItems = [];

  pageSize = '20';

  itemType = 'Consumable';

  filterVisible = false;
  columns = [
    {
      label: 'Name',
      dataIndex: 'name',
      search: true,
      isVisible: false,
      searchValue: undefined,
    },
   {
      label: 'Serial number',
      dataIndex: 'serialNumber',
      search: true,
      isVisible: false,
      searchValue: undefined,
      filterItemType: ['Asset'],
      sortOrder: null,
      sortFn: (a, b) => (a.serialNumber || "").localeCompare(b.serialNumber || ""),
      sortDirections: ['ascend', 'descend', null],
    },
    {
      label: 'Reference number',
      dataIndex: 'referenceNumber',
      search: true,
      isVisible: true,
      searchValue: undefined,
      sortOrder: null,
      sortFn: (a, b) => (a.referenceNumber || "").localeCompare(b.referenceNumber || ""),
      sortDirections: ['ascend', 'descend', null],
    },
    {
      label: 'Brand',
      dataIndex: 'brand',
      search: true,
      isVisible: false,
      searchValue: undefined,
    },
    { label: 'Price' },
    {
      label: 'Category',
      dataIndex: 'category.value',
      search: true,
      isVisible: false,
      searchValue: undefined,
    },
    { label: 'Amount', filterItemType: ['Consumable', 'Equipment'] },
    { label: 'Actions' },
  ];
  filterableColumns = [];

  constructor(
    private modalService: NzModalService,
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.filterableColumns = this.columns.filter((x) => x.search === true);
    this.activatedRoute.queryParams.subscribe((r) => {
      this.itemType = !r.itemType ? 'Consumable' : r.itemType;
      this.columns
        .filter((x) => x.search === true)
        .forEach((field) => {
          if (r[field.dataIndex]) field.searchValue = r[field.dataIndex];
        });
      this.load();
    });
  }

  filterByType(type) {
    if (type === 'All') {
      this.itemType = '';
    } else {
      this.itemType = type;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { itemType: this.itemType },
      queryParamsHandling: 'merge',
    });

    this.load();
  }


  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            items(type: "${this.itemType}") {
              id
              name
              type
              description
              brand
              serialNumber
              referenceNumber
              type
              price
              atWarehouses {
                id
                amount
              }
              category {
                id
                value
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.items = r.data.items.map((x) => ({
          ...x,
          amount: sum(x.atWarehouses.map((y) => y.amount)),
        }));
        this.filterData();
        this.isLoading = false;
      });
  }
  filterVisiblebtn(){
   this.filterVisible = true;
  }
  newItem() {
    this.modalService
      .create({
        nzTitle: 'Add new item',
        nzFooter: null,
        nzContent: ItemsModifyComponent,
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  editItem(item) {
    this.modalService
      .create({
        nzTitle: 'Edit item',
        nzFooter: null,
        nzContent: ItemsModifyComponent,
        nzData: {
          item: item,
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }

  filterData() {
    this.displayItems = this.items;
    var queryParams = {};

    this.columns.forEach((field) => {
      if (field.searchValue) {
        this.displayItems = this.displayItems.filter(
          (x) =>
            x[field.dataIndex] &&
            x[field.dataIndex]
              .toLowerCase()
              .includes(field.searchValue.toLowerCase())
        );
        queryParams[field.dataIndex] = field.searchValue;
      } else {
        queryParams[field.dataIndex] = null;
      }
    });

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }


/*   pageIndexChange = () =>
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page: this.page },
      queryParamsHandling: 'merge',
    }); */
}
