import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { SuppliersListComponent } from './list/list.component';
import { SupplierViewComponent } from './view/view.component';

export const routes: Routes = [
  {
    path: 'suppliers',
    component: SuppliersListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'supplier/:id',
    component: SupplierViewComponent,
    canActivate: [AuthGuard],
  },
];
