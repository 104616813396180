<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Warehouses">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="newWarehouse()">
        Add new warehouse
      </button>
    </nz-page-header-extra>
  </nz-page-header>

  <div nz-row nzGutter="12">
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="12"
      nzLg="12"
      nzXl="8"
      *ngFor="let item of list"
    >
      <nz-card
        nzSize="small"
        [nzTitle]="item.name"
        style="margin-bottom: 12px"
        nzHoverable
        [nzActions]="[actionSetting, actionOpen]"
      >
        <h4>Address: {{ item.address }}</h4>
        <h4>Postal code: {{ item.postalCode }}</h4>
        <h4>Region: {{ item.region }}</h4>
        <h4>Stock value: €{{ item.stockValue }}</h4>

        <ng-template #actionSetting>
          <div
            nz-tooltip
            nzTooltipTitle="Settings"
            (click)="editWarehouse(item)"
          >
            <i class="fas fa-cogs"></i>
          </div>
        </ng-template>
        <ng-template #actionOpen>
          <div
            nz-tooltip
            nzTooltipTitle="Open"
            [routerLink]="['/warehouse', item.id]"
          >
          <span nz-icon nzType="arrows-alt" style="font-size: 20px;" nzTheme="outline"></span>
          </div>
        </ng-template>
      </nz-card>
    </div>
  </div>
</nz-spin>
