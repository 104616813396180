import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DispatchRequestsNewComponent } from 'src/app/dispatch-requests/new/new.component';
import { GraphQlService } from 'src/app/services/graphql.service';
import { CreateStockTemporaryComponent } from '../create-stock-temporary/create-stock-temporary.component';
import { ReturnFormComponent } from '../return-form/return-form.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { groupBy, keys, forEach, sumBy } from 'lodash'

@Component({
  selector: 'app-clients-locations-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class ClientsLocationsViewComponent implements OnInit {
  isLoading;

  id;
  item;
  deliveredConsumables;

  constructor(
    private location: Location,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo,
    private graphQlService: GraphQlService,
    private messageService: NzMessageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((r) => {
      this.id = r['id'];
      this.load();
      this.loadDeliveredConsumables();
    });
  }

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`{ clientsLocation(id:"${this.id}"){ id name client { id } items { id isUsed amount item { id serialNumber type name } } } }`,
      })
      .subscribe((r: any) => {
        this.item = r.data.clientsLocation;
        this.isLoading = false;
      });
  }

  toList = () => this.location.back();

  newDispatchRequest(item) {
    this.modalService.create({
      nzWidth: '90%',
      nzTitle: 'New dispatch request',
      nzFooter: null,
      nzContent: DispatchRequestsNewComponent,
      nzData: {
        clientId: item?.client?.id,
        clientLocationId: item.id,
      },
    });
  }

  newReturnRequest(item) {
    this.modalService.create({
      nzWidth: '90%',
      nzTitle: 'New return request',
      nzFooter: null,
      nzContent: ReturnFormComponent,
      nzData: {
        clientId: item?.client?.id,
        clientLocationId: item.id,
        items: this.item.items,
      },
    });
  }

  createStock = () => {
    this.modalService
      .create({
        nzTitle: 'Create stock',
        nzContent: CreateStockTemporaryComponent,
        nzData: {
          clientLocationId: this.id,
        },
        nzWidth: '80%',
        nzFooter: null,
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  };

  loadDeliveredConsumables() {
    this.isLoading = true;
    this.graphQlService
      .get(
        `{ clientsLocation(id:"${this.id}") { consumablesDelivered { amount item { id name } } } }`
      )
      .subscribe((r: any) => {
        this.deliveredConsumables = r.data.clientsLocation.consumablesDelivered;
        this.isLoading = false;
      });
  }


  tabChanged(event) {
    if (event.index === 1) this.loadDeliveredConsumables();
  }


  /// History
  /// To Edit

  createModalVisible = false;
  basicTableData = [];
  history = [
    {
      id: '1',
      createdOn: '2023-07-19',
      comment: 'Example Comment 1',
      transport:  'FEMA',
      value: '62.05€',
      document: { filename: 'example-doc-1.txt', googleDocLocation: 'https://example.com/doc-1' },
      link: 'http://demo.leap-app.eu/delivery/e989c553-76f2-4d8c-9f61-1e80fddf7c8d',

    },
    {
      id: '2',
      createdOn: '2023-07-18',
      comment: 'Example Comment 2',
      transport:  'Santos e Vale',
      value: '15.05€',
      document: { filename: 'example-doc-2.txt', googleDocLocation: 'https://example.com/doc-2' },
      link: 'http://demo.leap-app.eu/delivery/e989c553-76f2-4d8c-9f61-1e80fddf7c8d',
    },
    {
      id: '3',
      createdOn: '2023-07-12',
      comment: 'Example Comment 3',
      transport:  'Alpibre',
      value: '10.50€',
      document: { filename: 'example-doc-3.txt', googleDocLocation: 'https://example.com/doc-3' },
      link: 'http://demo.leap-app.eu/delivery/e989c553-76f2-4d8c-9f61-1e80fddf7c8d',
    },
  ];

  loadHistory(): void {
    this.isLoading = true;
    this.apollo
      .use('common')
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            deliveries {
              id
              createdOn
              comment
              user {
                id
                username
              }
              date
              document {
                filename
                googleDocLocation
              }
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.basicTableData = r.data.deliveries;
        this.history = r.data.deliveries;
        this.isLoading = false;
      });
  }
  openItem(itemId: string): void {
    const item = this.history.find((x) => x.id === itemId);

    if (item) {
      this.createModalVisible = true;
      this.item = { ...item };
    } else {
      this.messageService.error('Item not found.');
    }
  }
  remove(itemId: string): void {
    this.history = this.history.filter((x) => x.id !== itemId);
    this.messageService.success('Item removed successfully.');
    this.createModalVisible = false;
  }


  cancel(): void {
    this.createModalVisible = false;
    this.item = null;
  }

}
