<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Name
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="username" placeholder="Name" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Email
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="email" placeholder="Email" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Password
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="password" placeholder="Password" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px" *ngIf="item">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Is active
      </div>
      <div nz-col nzSpan="12">
        <nz-switch formControlName="isActive"></nz-switch>
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px" *ngIf="item">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Roles
      </div>
      <div nz-col nzSpan="12">
        <nz-select  [(ngModel)]="selectedRoles" style="width: 100%" nzMode="multiple"
        [ngModelOptions]="{ standalone: true }">
          <nz-option *ngFor="let role of roles" [nzLabel]="role.value" [nzValue]="role.id"></nz-option>
        </nz-select>
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">
        {{ item ? "Update" : "Create" }}
      </button>
    </div>
  </form>
</nz-spin>