import { Component, OnInit } from '@angular/core';
import { GraphQlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
  warehouseLowStocks = [];
  lowStockWarehouse = undefined;

  constructor(private graphQlService: GraphQlService) {}

  ngOnInit(): void {
    this.graphQlService
      .get(
        `{ warehouses { id name lowStock { amount lowStockAmount item { id name } } } }`
      )
      .subscribe((r) => {
        console.log('r', r);
        this.warehouseLowStocks = r.data['warehouses'];
      });
  }
}
