import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfigsItemsKitsModifyComponent } from '../modify/modify.component';

@Component({
  selector: 'app-configs-items-kits-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class ConfigsItemsKitsListComponent implements OnInit {
  constructor(private modalService: NzModalService) {}

  ngOnInit(): void {}

  new() {
    this.modalService.create({
      nzTitle: 'Add new kit',
      nzFooter: null,
      nzContent: ConfigsItemsKitsModifyComponent,
      nzWidth: '80%',
    });
  }

  edit(id) {
    this.modalService.create({
      nzTitle: 'Edit kit',
      nzFooter: null,
      nzContent: ConfigsItemsKitsModifyComponent,
      nzData: {
        id: id,
      },
      nzWidth: '80%',
    });
  }
}
