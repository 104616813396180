<nz-page-header nzTitle="QR Scanner"> </nz-page-header>

<nz-spin [nzSpinning]="isLoading">
<button
  nz-button
  nzShape="round"
  style="margin-right: 5px"
  (click)="scan()"
  nzSize="large"
>
  Open scanner
</button>
</nz-spin>

<nz-modal
  [(nzVisible)]="manageStockWarehouseItemId"
  nzTitle="Manage stock"
  [nzFooter]="null"
  nzWidth="90%"
>
  <div style="text-align: center">
    <button
      nz-button
      nzShape="round"
      style="margin-right: 5px"
      (click)="manageStockIncrease(manageStockWarehouseItemId)"
    >
      Increase stock
    </button>
    <button
      nz-button
      nzShape="round"
      (click)="manageStockDecrease(manageStockWarehouseItemId)"
    >
      Decrease stock
    </button>
  </div>
</nz-modal>
