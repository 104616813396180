import { NgModule } from '@angular/core';
import { CommonModule as CommonModuleAngular } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTransferModule } from 'ng-zorro-antd/transfer';

import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { HttpClientModule } from '@angular/common/http';
import { GraphQLModule } from './graphql.module';
import { AuthGuard } from './auth/login/auth-guard';
import { MapComponent } from './common/map/map.component';
import { QrGeneratorComponent } from './common/qr-generator/qr-generator.component';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ApolloModule } from 'apollo-angular';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [MapComponent, QrGeneratorComponent],
  exports: [
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzGridModule,
    NzMenuModule,
    NzSpinModule,
    NzInputModule,
    NzButtonModule,
    NzProgressModule,
    NzToolTipModule,
    NzAvatarModule,
    NzModalModule,
    NzTableModule,
    NzCardModule,
    NzDrawerModule,
    NzInputNumberModule,
    NzDividerModule,
    NzDropDownModule,
    NzAutocompleteModule,
    NzUploadModule,
    NzTagModule,
    NzTabsModule,
    NzPaginationModule,
    NzListModule,
    NzNotificationModule,
    NzStatisticModule,
    NzCommentModule,
    NzAlertModule,
    NzStepsModule,
    NzBadgeModule,
    NzSelectModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzCheckboxModule,
    NzEmptyModule,
    NzSliderModule,
    NzPopoverModule,
    NzMessageModule,
    NzTimelineModule,
    NzLayoutModule,
    NzIconModule,
    NzPageHeaderModule,
    NzSwitchModule,
    NzDescriptionsModule,
    NzCollapseModule,
    NzTransferModule,
    GraphQLModule,
    MapComponent,
    QrGeneratorComponent
  ],
  imports: [
    CommonModuleAngular,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NzGridModule,
    NzMenuModule,
    NzSpinModule,
    NzInputModule,
    NzButtonModule,
    NzProgressModule,
    NzToolTipModule,
    NzAvatarModule,
    NzModalModule,
    NzSelectModule,
    NzTableModule,
    NzCardModule,
    NzDrawerModule,
    NzInputNumberModule,
    NzDividerModule,
    NzDropDownModule,
    NzTabsModule,
    NzAutocompleteModule,
    NzUploadModule,
    NzTagModule,
    NzTabsModule,
    NzPaginationModule,
    NzListModule,
    NzNotificationModule,
    NzStatisticModule,
    NzCommentModule,
    NzAlertModule,
    NzStepsModule,
    NzBadgeModule,
    NzDatePickerModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzCheckboxModule,
    NzEmptyModule,
    NzSliderModule,
    NzPopoverModule,
    NzMessageModule,
    NzTimelineModule,
    NzLayoutModule,
    NzIconModule,
    NzPageHeaderModule,
    NzSwitchModule,
    NzDescriptionsModule,
    NzCollapseModule,
    NzTransferModule,
    GraphQLModule,
    QRCodeModule,
    // NgxQRCodeModule,
    ApolloModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, AuthGuard],
})
export class SharedModule { }
