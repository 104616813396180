import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuppliersListComponent } from './list/list.component';
import { SharedModule } from '../shared.module';
import { SupplierModifyComponent } from './modify/modify.component';
import { SupplierViewComponent } from './view/view.component';
import { SupplierItemAddPriceComponent } from './items/add-price/add-price.component';
import { SupplierItemPriceHistoryComponent } from './items/price-history/price-history.component';

@NgModule({
  declarations: [
    SuppliersListComponent,
    SupplierModifyComponent,
    SupplierViewComponent,
    SupplierItemAddPriceComponent,
    SupplierItemPriceHistoryComponent
  ],
  imports: [
    CommonModule, SharedModule
  ]
})
export class SuppliersModule { }
