<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Name
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="name" placeholder="Name" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Type
      </div>
      <div nz-col nzSpan="12">
        <nz-select style="width: 100%" formControlName="type" [nzDisabled]="item">
          <nz-option
            *ngFor="let i of ['Consumable', 'Asset', 'Equipment']"
            [nzValue]="i"
            [nzLabel]="i"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Description
      </div>
      <div nz-col nzSpan="12">
        <textarea
          rows="4"
          formControlName="description"
          nz-input
          placeholder="Description"
        ></textarea>
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px" *ngIf="form.get('type').value === 'Asset'">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Serial number
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="serialNumber" placeholder="Serial number" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Reference number
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="referenceNumber" placeholder="Reference number" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Brand
      </div>
      <div nz-col nzSpan="12">
        <input nz-input formControlName="brand" placeholder="Brand" />
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Price
      </div>
      <div nz-col nzSpan="12">
        <nz-input-number
          formControlName="price"
          [nzPrecision]="2"
          nzPrecisionMode="cut"
          nzPlaceHolder="Price"
        ></nz-input-number>
      </div>
    </div>

    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Category
      </div>
      <div nz-col nzSpan="12">
        <nz-select style="width: 100%" formControlName="categoryId">
          <nz-option
            *ngFor="let i of categories"
            [nzValue]="i.id"
            [nzLabel]="i.value"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">
        {{ item ? "Update" : "Create" }}
      </button>
    </div>
  </form>
</nz-spin>
