import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { View, Feature, Map } from 'ol';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import * as olProj from 'ol/proj';
import Icon from 'ol/style/Icon';
import Polyline from 'ol/format/Polyline';
import { HttpClient } from '@angular/common/http';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-common-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
})
export class MapComponent implements OnInit {
  isLoading = false;

  @Input() locations = [];
  @Input() zoom = 8;
  @Input() currentPosition;
  @Output() mapLoaded = new EventEmitter();

  map;

  constructor(
    private modalService: NzModalService,
    private apollo: Apollo,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    if (!this.currentPosition)
      this.currentPosition = { longitude: -8.4513795, latitude: 40.2288202 };
    if (!this.locations) this.locations = [];

    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: olProj.fromLonLat([
          this.currentPosition.longitude,
          this.currentPosition.latitude,
        ]),
        zoom: this.zoom,
      }),
    });
    console.log('map loaded', this.locations);

    this.httpClient
      .get(
        `http://router.project-osrm.org/route/v1/driving/${this.locations
          .map((x) => `${x.geoLocationLong},${x.geoLocationLat}`)
          .join(';')}`
      )
      .subscribe((r: any) => {
        console.log('route', r);
        if (r.code === 'Ok') {
          this.createRoute(r.routes[0].geometry);
        }
      });

    this.mapLoaded.emit(true);
  }

  createRoute(polyline) {
    var route = new Polyline({
      factor: 1e5,
    }).readGeometry(polyline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    var feature = new Feature({
      type: 'route',
      geometry: route,
    });
    var layer = new VectorLayer({
      source: new VectorSource({
        // projection: 'EPSG:4326',
        features: [feature, new Feature()],
      }),
    });

    this.map.addLayer(layer);
    console.log('createRoute', layer, route);
  }

  setPoint(port) {
    // var feature = new Feature({
    //   geometry: new Point(
    //     olProj.fromLonLat([
    //       port.geolocation.longitude,
    //       port.geolocation.latitude,
    //     ])
    //   ),
    //   name: port.symbol,
    //   id: port.id,
    // });

    // feature.setStyle(
    //   new Style({
    //     image: new Icon({
    //       color: '#BADA55',
    //       crossOrigin: 'anonymous',
    //       imgSize: [30, 30],
    //       src: 'assets/anchor-solid.svg',
    //     }),
    //   })
    // );

    // var layer = new VectorLayer({
    //   source: new VectorSource({
    //     // projection: 'EPSG:4326',
    //     features: [feature, new Feature()],
    //   }),
    // });

    // this.map.addLayer(layer);
  }
}
