import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { ConfigsItemsListComponent } from './items-categories/list/list.component';
import { ConfigsItemsModifyComponent } from './items-categories/modify/modify.component';
import { ConfigsItemsKitsModifyComponent } from './items-kits/modify/modify.component';
import { ConfigsItemsKitsListComponent } from './items-kits/list/list.component';

@NgModule({
  declarations: [
    ConfigsItemsListComponent,
    ConfigsItemsModifyComponent,
    ConfigsItemsKitsModifyComponent,
    ConfigsItemsKitsListComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class ConfigsModule {}
