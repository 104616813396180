<nz-spin [nzSpinning]="isLoading">
  <nz-page-header (nzBack)="toList()" nzBackIcon nzTitle="{{ item?.type }}: {{ id }}">
    <nz-page-header-content>
      <nz-descriptions nzSize="small" [nzColumn]="2">
        <nz-descriptions-item nzTitle="Client" [nzSpan]="1"
          *ngIf="item?.type === 'dispatch' || item?.type === 'return'">
          {{ item?.client?.name }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Location" [nzSpan]="1"
          *ngIf="item?.type === 'dispatch' || item?.type === 'return'">
          {{ item?.clientLocation?.name }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Warehouse" [nzSpan]="1"
          *ngIf="item?.type === 'dispatch' || item?.type === 'transfer'">
          {{ item?.warehouse?.name }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Target warehouse" [nzSpan]="1"
          *ngIf="item?.type === 'return' || item?.type === 'transfer'">
          {{ item?.targetWarehouse?.name }}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Status" [nzSpan]="1">
          <nz-tag [nzColor]="getStatusColor(item?.status)">
            {{ item?.status }}
          </nz-tag>        
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="item?.createdOn" nzTitle="Created On" [nzSpan]="1">
          {{ formatDate(item?.createdOn) }}
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="item?.startDateTime" nzTitle="startDateTime" [nzSpan]="1">
          {{ formatDate(item?.startDateTime) }}
        </nz-descriptions-item>
        <nz-descriptions-item *ngIf="item?.finishDateTime" nzTitle="finishDateTime" [nzSpan]="1">
          {{ formatDate(item?.finishDateTime) }}
        </nz-descriptions-item>
      </nz-descriptions>
      <button nz-button nzType="primary" nzShape="round" (click)="isVisiblebtn()">Add Item</button>
    </nz-page-header-content>
  </nz-page-header>

  
  <nz-card *ngIf="isVisible">
    <form nz-form [formGroup]="form" (ngSubmit)="addItem()">
      <div nz-row nzGutter="16" style="margin-bottom: 5px" *ngIf="items.length > 0">
        <div nz-col nzXs="24" nzSm="24" nzLg="8" style="margin-top: 3px">
          <nz-select
            class="custom-select"
            nzShowSearch
            [(ngModel)]="newItem.itemId"
            [ngModelOptions]="{ standalone: true }"
          >
            <nz-option
              nzCustomContent
              *ngFor="let i of items"
              [nzValue]="i.id"
              nzLabel="{{ i.isUsed ? '[U]' : '[N]' }} {{ i.item.name }}"
              [nzHide]="!isNotSelected(i.id)"
            >
              <nz-tag>{{ i.isUsed ? "[U]" : "[N]" }}</nz-tag>
              {{ i.item.name }}
              <nz-tag *ngIf="i.item.serialNumber">{{ i.item.serialNumber }}</nz-tag>
              <nz-tag *ngIf="!i.item.serialNumber">In stock: {{ i.amount }}</nz-tag>
            </nz-option>
          </nz-select>
        </div>
        <div nz-col nzXs="12" nzSm="12" nzLg="8" style="margin-top: 3px">
          <nz-input-number
            style="width: 100%"
            [nzMin]="1"
            nzPlaceHolder="Amount"
            [nzStep]="1"
            [(ngModel)]="newItem.amount"
            [ngModelOptions]="{ standalone: true }"
          ></nz-input-number>
        </div>
        <div
          nz-col
          nzXs="12"
          nzSm="12"
          nzLg="8"
          style="margin-top: 3px; text-align: center"
        >
          <button
            nz-button
            type="button"
            nzType="primary"
            nzShape="round"
            (click)="addItem()"
          >
            Add item to list
          </button>
        </div>
      </div>
    </form>
  </nz-card>


  <nz-card>
    <h4>Items</h4>
    <nz-list nzItemLayout="horizontal" nzBordered style="margin-bottom: 5px">
      <nz-list-item *ngFor="let i of selectedItems">
        <ng-container>
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              <div nz-row nzGutter="8">
                <div nz-col nzSpan="8">
                  <a [routerLink]="['item', i.item.id]">{{ i.item.name }} {{ i.isUsed ? "(Used)" : "" }}</a>
                </div>
                <div nz-col nzSpan="8" *ngIf="i.item.serialNumber">
                  (SN: {{ i.item.serialNumber }})
                </div>
                <div nz-col nzSpan="8" *ngIf="i.item.referenceNumber">
                  (RN: {{ i.item.referenceNumber }})
                </div>
              </div>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          Amount: {{ i.amount }}
          <nz-list-item-actions>
            <button nz-button type="button" style="margin-left: 5px;" nzShape="round" (click)="removeItem(i.item.id)">
              <i class="far fa-trash-alt"></i>
            </button>
          </nz-list-item-actions>
        </ng-container>
      </nz-list-item>
      <nz-list-empty *ngIf="selectedItems.length === 0"></nz-list-empty>
    </nz-list>
  </nz-card>
</nz-spin>