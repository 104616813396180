import { EventEmitter, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl;
  apiUrlChanged = new EventEmitter();

  isLoggedIn = new EventEmitter<boolean>();

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    this.apiUrlChanged.subscribe(r => { this.apiUrl = r; this.createClient(); });
  }

  createClient = () => {
    this.apollo.removeClient(`common`);

    const auth = setContext((operation, context) => {
      const token = localStorage.getItem('jwtToken');
      if (token === null) {
        return {};
      } else {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }
    });
    const error = onError(({ graphQLErrors, networkError }) => {
      console.log('test', graphQLErrors);
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
        if (
          (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code === 'authorization') ||
          graphQLErrors[0].message.includes(
            `You are not authorized to run this query`
          )
        ) {
          console.log('redirect?')
          localStorage.clear();
          window.location.assign(`/login`);
          setTimeout(() => {
            window.location.reload();
          }, 100);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        localStorage.clear();
        window.location.assign(`/login`);
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    });

    this.apollo.create({
      cache: new InMemoryCache(),
      link: ApolloLink.from([
        error,
        auth,
        this.httpLink.create({
          uri: `${this.apiUrl}/api/graphql`,
        }),
      ]),
    }, 'common');
  }
}
