import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveriesListComponent } from './list/list.component';
import { DeliveriesViewComponent } from './view/view.component';
import { SharedModule } from '../shared.module';
import { DeliveriesModifyComponent } from './modify/modify.component';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

@NgModule({
  declarations: [
    DeliveriesListComponent,
    DeliveriesViewComponent,
    DeliveriesModifyComponent,
  ],
  imports: [CommonModule, SharedModule],
})
export class DeliveriesModule {}
