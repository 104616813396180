<nz-spin [nzSpinning]="isLoading">
  <nz-page-header nzTitle="Clients">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="new()">Add new client</button>
      <button nz-button nzShape="round" (click)="filterVisiblebtn()">
        <i class="fas fa-search"></i>
      </button>
    </nz-page-header-extra>
    <nz-page-header-content>
      <div  *ngIf="filterVisible">
        <div nz-row *ngFor="let field of filterModalFields" style="text-align: center; margin-top: 15px">
          <div nz-col nzSpan="12"> 
            <input nz-input placeholder="Search for Name" style="width: 100%" [(ngModel)]="field.searchValue" />
            <button nz-button nzType="primary" style="float: right; margin-top: 5px;" nzShape="round" (click)="filterData()">
              Search  <i style="margin-left: 5px;" class="fas fa-search"></i>
            </button>
          </div>
          
        </div>
      </div>
    </nz-page-header-content>
  </nz-page-header>

  <nz-table #basicTable nzSize="small" [nzData]="displayItems" style="min-width: 100%" [nzScroll]="{ x: '800px' }"
    [nzPageSize]="30">
    <thead>
      <tr>
        <th>Name</th>
        <th>Locations</th>
        <th>Status</th>
        <th>
          <!--   <button nz-button nzShape="round" nzSize="small" (click)="filterModalVisible = true">
            <i class="fas fa-search"></i>
          </button> -->
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of basicTable.data">
        <td>{{ item.name }}</td>
        <td>{{ item.locations?.length }}</td>
        <td>
          <nz-tag [nzColor]="'volcano'" *ngIf="item.isActive === false">Inactive</nz-tag>
          <nz-tag [nzColor]="'green'" *ngIf="item.isActive === true">Active</nz-tag>
        </td>
        <td>
          <button nz-button nzShape="round" nzSize="small" style="margin-right: 3px"
            [routerLink]="['/client', item.id]">
            <i class="far fa-eye"></i>
          </button>
          <button nz-button nzShape="round" nzSize="small" style="margin-right: 3px" (click)="edit(item)">
            <i class="fas fa-cogs"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
</nz-spin>

<!-- <nz-modal [(nzVisible)]="filterModalVisible" nzTitle="Filters/searches" (nzOnCancel)="filterModalVisible = false"
  (nzOnOk)="filterData()" nzWidth="80%">
  <div nz-row style="text-align: center">
    <div nz-col [nzSpan]="8">Field</div>
    <div nz-col [nzSpan]="8">Search</div>
    <div nz-col [nzSpan]="8">Order by</div>
  </div>
  <div nz-row *ngFor="let field of filterModalFields" style="text-align: center; margin-top: 15px">
    <div nz-col [nzSpan]="8">{{ field.name }}</div>
    <div nz-col [nzSpan]="8">
      <input nz-input placeholder="Search value" style="width: 80%" [(ngModel)]="field.searchValue" />
    </div>
  </div>
</nz-modal> -->