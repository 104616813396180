import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { WarehouseListComponent } from './list/list.component';
import { WarehouseViewComponent } from './view/view.component';

export const routes: Routes = [
  {
    path: 'warehouses',
    component: WarehouseListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'warehouse/:id',
    component: WarehouseViewComponent,
    canActivate: [AuthGuard],
  },
];
