import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-warehouse-item-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.less'],
})
export class WarehouseItemModifyComponent implements OnInit {
  isLoading = false;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });
  form: FormGroup;
  @Input() item;

  lowStockAmountEnabled = false;

  constructor(private apollo: Apollo, private modalRef: NzModalRef) {
    this.form = new FormGroup({
      warehouseItemId: new FormControl(null, [Validators.required]),
      lowStockAmount: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.item = this.nzModalData.item;

    this.form.get(`warehouseItemId`).setValue(this.item.id);
    this.form.get(`lowStockAmount`).setValue(this.item.lowStockAmount);
    if (this.item.lowStockAmount && this.item.lowStockAmount > 0)
      this.lowStockAmountEnabled = true;
  }

  toggleLowStockEnabled = () => {
    this.lowStockAmountEnabled = !this.lowStockAmountEnabled;
    this.form
      .get(`lowStockAmount`)
      .setValue(this.lowStockAmountEnabled === false ? null : 1);
  };

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: ModifyWarehouseItemCommand) {
            modifyWarehouseItem(data: $data)
          }
        `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.modalRef.close('ok');
      });
  }
}
