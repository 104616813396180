<nz-layout class="layout">
  <nz-sider
    class="no-print"
    nzCollapsible
    nzBreakpoint="lg"
    [nzCollapsedWidth]="0"
    *ngIf="isLoggedIn === true"
  >
    <app-layout-sidebar></app-layout-sidebar>
  </nz-sider>
  <nz-layout class="right-layout">
    <!-- <nz-header></nz-header> -->
    <nz-content>
      
      <div class="inner-content" >
        <router-outlet></router-outlet>
      </div>
   
    </nz-content>
  </nz-layout>
</nz-layout>
