import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';

import { WarehousesModule } from './warehouses/warehouses.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';
import { ItemsModule } from './items/items.module';
import { ConfigsModule } from './configs/configs.module';
import { ClientsModule } from './clients/clients.module';
import { ClientsLocationsModule } from './clients-locations/clients-locations.module';
import { DispatchRequestsModule } from './dispatch-requests/dispatch-requests.module';
import { DeliveriesModule } from './deliveries/deliveries.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { ListFiltersSearchesComponentComponent } from './common/list-filters-searches-component/list-filters-searches-component.component';
import { UsersListComponent } from './users/list/list.component';
import { LoginComponent } from './auth/login/login.component';
import { UsersModifyComponent } from './users/modify/modify.component';
import { QrScannerComponent } from './common/qr-scanner/qr-scanner.component';
import { LegalComponent } from './common/legal/legal.component';
import { SuppliersModule } from './suppliers/suppliers.module';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DashboardComponent,
    ListFiltersSearchesComponentComponent,
    UsersListComponent,
    LoginComponent,
    UsersModifyComponent,
    QrScannerComponent,
    LegalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,

    SharedModule,
    WarehousesModule,
    ItemsModule,
    ConfigsModule,
    ClientsModule,
    ClientsLocationsModule,
    DispatchRequestsModule,
    DeliveriesModule,
    SuppliersModule,
    GraphQLModule,
    HttpClientModule
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
