<form>
  <div nz-row nzGutter="16" style="margin-bottom: 10px">
    <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
      Name
    </div>
    <div nz-col nzSpan="12">
      <input nz-input placeholder="Name" />
    </div>
  </div>

  <h4>Items</h4>
  <ul
    nz-list
    [nzDataSource]="items"
    nzBordered
    nzSize="default"
    style="margin-bottom: 5px"
  >
    <li nz-list-item *ngFor="let item of items" nzNoFlex>
      <ul nz-list-item-actions>
        <nz-list-item-action>
          <button nz-button nzShape="round" nzSize="small" nzDanger>
            <i class="far fa-trash-alt"></i>
          </button>
        </nz-list-item-action>
      </ul>
      {{ item.amount }} {{ item.name }}
    </li>
  </ul>

  <div nz-row nzGutter="16" style="margin-bottom: 5px">
    <div nz-col nzSpan="8">
      <nz-select style="width: 100%" nzShowSearch>
        <nz-option
          *ngFor="let i of [1, 2, 3]"
          [nzValue]="i"
          nzLabel="Item {{ i }}"
        ></nz-option>
      </nz-select>
    </div>
    <div nz-col nzSpan="8">
      <nz-input-number
        style="width: 100%"
        [nzMin]="1"
        nzPlaceHolder="1"
        [nzStep]="1"
      ></nz-input-number>
    </div>
    <div nz-col nzSpan="8">
      <button nz-button nzType="primary" nzShape="round">
        Add item to list
      </button>
    </div>
  </div>

  <nz-divider></nz-divider>
  <div style="text-align: center">
    <button nz-button nzType="primary" nzShape="round">
      {{ id ? "Update" : "Create" }}
    </button>
  </div>
</form>
