import { Component, inject, Input, OnInit } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-supplier-item-price-history',
  templateUrl: './price-history.component.html',
  styleUrls: ['./price-history.component.less']
})
export class SupplierItemPriceHistoryComponent implements OnInit {
  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() history;

  constructor(
  ) { }

  ngOnInit(): void {
    this.history = this.nzModalData.history;
    console.log('history', this.history)
  }

}
