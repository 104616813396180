import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ClientsLocationsModifyComponent } from '../modify/modify.component';

@Component({
  selector: 'app-clients-locations-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class ClientsLocationsListComponent implements OnInit {
  isLoading;

  @Input() clientId;

  list = [];
  displayItems = [];
  filterVisible = false;
  filterModalVisible = false;
  filterModalFields = [
    {
      name: 'Name',
      dataIndex: 'name',
      searchValue: undefined,
    },
    {
      name: 'Street',
      dataIndex: 'street',
      searchValue: undefined,
    },
    {
      name: 'Number',
      dataIndex: 'number',
      searchValue: undefined,
    },
    {
      name: 'Postal code',
      dataIndex: 'postalCode',
      searchValue: undefined,
    },
  ];

  constructor(
    private modalService: NzModalService,
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((r) => {
      this.filterModalFields.forEach((field) => {
        if (r[field.dataIndex]) field.searchValue = r[field.dataIndex];
      });
      this.load();
    });
  }

  load() {
    this.isLoading = true;
    var query = gql`
      {
        clientsLocations {
          id
          name
          street
          number
          postalCode geoLocationLat geoLocationLong
          stockValue
          isActive
          client {
            id
            name
            isActive
          }
        }
      }
    `;
    if (this.clientId)
      query = gql`
        {
          client(id:"${this.clientId}") {
            locations { id name street number postalCode geoLocationLat geoLocationLong stockValue }
          }
        }
      `;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: query,
      })
      .subscribe((r: any) => {
        if (this.clientId) {
          var list = r.data.client?.locations;
          if (!list) list = [];
          list.forEach((item) => {
            item['client'] = { id: this.clientId };
          });
          this.list = list;
        } else this.list = r.data.clientsLocations.filter(x => x.client && x.client.isActive === true);
        this.filterData();
        this.isLoading = false;
      });
  }
  filterVisiblebtn(){
    this.filterVisible = true;
   }

  edit(item) {
    this.modalService
      .create({
        nzTitle: 'Edit client location',
        nzFooter: null,
        nzContent: ClientsLocationsModifyComponent,
        nzData: {
          item: item,
          clientId: this.clientId ? this.clientId : item?.client?.id,
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') this.load();
      });
  }
  filterData() {
    this.displayItems = this.list;
    var queryParams = {};
    this.filterModalFields.forEach((field) => {
      if (field.searchValue && field.searchValue.trim() !== '') {
        this.displayItems = this.displayItems.filter((x) => {
          const fieldValue = x[field.dataIndex];
          if (fieldValue && typeof fieldValue === 'string') {
            return fieldValue.toLowerCase().includes(field.searchValue.toLowerCase());
          }
          return false;
        });
        queryParams[field.dataIndex] = field.searchValue;
      }
    });

    this.filterModalFields.forEach((field) => {
      field.searchValue = undefined;
    });

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }



}
