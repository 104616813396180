<nz-spin [nzSpinning]="isLoading">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
    <div nz-row nzGutter="16" style="margin-bottom: 10px">
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Warehouse
      </div>
      <div nz-col nzSpan="12">
        <nz-select
          style="width: 100%"
          formControlName="warehouseId"
          (ngModelChange)="loadWarehouseItems($event)"
        >
          <nz-option
            *ngFor="let i of warehouses"
            [nzValue]="i.id"
            [nzLabel]="i.name"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div
      nz-row
      nzGutter="16"
      style="margin-bottom: 10px"
      *ngIf="!clientLocationId"
    >
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Client
      </div>
      <div nz-col nzSpan="12">
        <nz-select style="width: 100%" formControlName="clientId">
          <nz-option
            *ngFor="let i of clients"
            [nzValue]="i.id"
            [nzLabel]="i.name"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div
      nz-row
      nzGutter="16"
      style="margin-bottom: 10px"
      *ngIf="!clientLocationId"
    >
      <div nz-col nzSpan="8" style="text-align: right; padding-top: 5px">
        Location
      </div>
      <div nz-col nzSpan="12">
        <nz-select style="width: 100%" formControlName="clientLocationId">
          <nz-option
            *ngFor="let i of getClientLocations(form.get('clientId').value)"
            [nzValue]="i.id"
            [nzLabel]="i.name"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <h4>Items</h4>
    <nz-list nzItemLayout="horizontal" nzBordered style="margin-bottom: 5px">
      <nz-list-item *ngFor="let i of selectedItems">
        <ng-container>
          <nz-list-item-meta>
            <nz-list-item-meta-title>
              {{ i.name }} {{ i.isUsed ? "(Used)" : (New) }}
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          Amount: {{ i.amount }}
          <ul nz-list-item-actions>
            <nz-list-item-action>
              <button
                nz-button
                type="button"
                nzShape="round"
                nzSize="small"
                nzDanger
                (click)="removeItem(i.warehouseItemId)"
              >
                <i class="far fa-trash-alt"></i></button
            ></nz-list-item-action>
          </ul>
        </ng-container>
      </nz-list-item>
      <nz-list-empty *ngIf="selectedItems.length === 0"></nz-list-empty>
    </nz-list>

    <div
      nz-row
      nzGutter="16"
      style="margin-bottom: 5px"
      *ngIf="items.length > 0"
    >
      <div nz-col nzXs="24" nzSm="24" nzLg="8" style="margin-top: 3px">
        <nz-select
          style="width: 100%"
          nzShowSearch
          [(ngModel)]="newItem.itemId"
          [ngModelOptions]="{ standalone: true }"
        >
          <nz-option
            nzCustomContent
            *ngFor="let i of items"
            [nzValue]="i.id"
            nzLabel="{{ i.isUsed ? '[U]' : '[N]' }} {{ i.item.name }}"
            [nzHide]="!isNotSelected(i.id)"
          >
            <nz-tag>{{ i.isUsed ? "[U]" : "[N]" }} </nz-tag>
            {{ i.item.name }}
            <nz-tag *ngIf="i.item.serialNumber">{{
              i.item.serialNumber
            }}</nz-tag>
            <nz-tag *ngIf="!i.item.serialNumber"
              >In stock: {{ i.amount }}</nz-tag
            >
          </nz-option>
        </nz-select>
      </div>
      <div nz-col nzXs="12" nzSm="12" nzLg="8" style="margin-top: 3px">
        <nz-input-number
          style="width: 100%"
          [nzMin]="1"
          nzPlaceHolder="Amount"
          [nzStep]="1"
          [(ngModel)]="newItem.amount"
          [ngModelOptions]="{ standalone: true }"
        ></nz-input-number>
      </div>
      <div
        nz-col
        nzXs="12"
        nzSm="12"
        nzLg="8"
        style="margin-top: 3px; text-align: center"
      >
        <button
          nz-button
          type="button"
          nzType="primary"
          nzShape="round"
          (click)="addItem()"
        >
          Add item to list
        </button>
      </div>
    </div>

    <div style="text-align: center">
      <button nz-button nzType="primary" nzShape="round">Submit</button>
    </div>
  </form>
</nz-spin>
