import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-warehouses-create-stock',
  templateUrl: './create-stock.component.html',
  styleUrls: ['./create-stock.component.less'],
})
export class CreateStockComponent implements OnInit {
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  itemType;
  @Input() warehouseId;
  items = [];

  form: FormGroup;

  constructor(
    private apollo: Apollo,
    private modalRef: NzModalRef,
    private messageService: NzMessageService
  ) {
    this.form = new FormGroup({
      warehouseId: new FormControl(null, [Validators.required]),
      itemId: new FormControl(null, [Validators.required]),
      isUsed: new FormControl(false, [Validators.required]),
      amount: new FormControl(1, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.warehouseId = this.nzModalData.warehouseId;
    this.form.get(`warehouseId`).setValue(this.warehouseId);
    this.load();
  }

  load() {
    if (!this.itemType) return;
    this.form.get(`amount`).setValue(1);
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`
          {
            items(type: "${this.itemType}") {
              id
              name
              type
              serialNumber
            }
          }
        `,
      })
      .subscribe((r: any) => {
        this.items = r.data.items;
        this.isLoading = false;
      });
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: CreateWarehouseItemCommand) {
            createWarehouseItem(data: $data)
          }
        `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.messageService.success(`New stock has been added successfully.`);
        this.modalRef.close('ok');
      });
  }
}
