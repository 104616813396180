import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ClientsLocationsModifyComponent } from 'src/app/clients-locations/modify/modify.component';

@Component({
  selector: 'app-clients-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less'],
})
export class ClientsViewComponent implements OnInit {
  isLoading;

  id;
  item;

  constructor(
    private location: Location,
    private modalService: NzModalService,
    private activatedRoute: ActivatedRoute,
    private apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((r) => {
      this.id = r['id'];
      this.load();
    });
  }

  load() {
    this.isLoading = true;
    this.apollo
      .use(`common`)
      .query({
        fetchPolicy: 'no-cache',
        query: gql`{ client(id:"${this.id}"){ id name }}`,
      })
      .subscribe((r: any) => {
        this.item = r.data.client;
        this.isLoading = false;
      });
  }

  toList = () => this.location.back();

  new() {
    this.modalService
      .create({
        nzTitle: 'Add new location',
        nzFooter: null,
        nzContent: ClientsLocationsModifyComponent,
        nzData: {
          clientId: this.id,
        },
        nzWidth: '80%',
      })
      .afterClose.subscribe((r) => {
        if (r === 'ok') {
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 100);
        }
      });
  }
}
