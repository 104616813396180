import { Routes } from '@angular/router';
import { AuthGuard } from '../auth/login/auth-guard';
import { DispatchRequestsListComponent } from './list/list.component';
import { DispatchRequestsViewComponent } from './view/view.component';

export const routes: Routes = [
  {
    path: 'dispatch-requests',
    component: DispatchRequestsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dispatch-request/:id',
    component: DispatchRequestsViewComponent,
    canActivate: [AuthGuard],
  },
];
