<nz-page-header nzTitle="Items kits">
    <nz-page-header-extra>
      <button nz-button nzShape="round" (click)="new()">Add new kit</button>
    </nz-page-header-extra>
  </nz-page-header>
  
  <nz-table #basicTable nzSize="small" [nzData]="[{}]" style="min-width: 100%;" [nzScroll]="{ x: '800px' }">
    <thead>
      <tr>
        <th>Name</th>
        <th>Items added</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of [1, 2, 3, 4]">
        <td>Name {{ i }}</td>
        <td>{{ i }}</td>
        <td>
          <button
            nz-button
            nzShape="round"
            nzSize="small"
            style="margin-right: 3px"
            (click)="edit(i)"
          >
            <i class="fas fa-cogs"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </nz-table>
  