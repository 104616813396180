import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { NzModalService } from 'ng-zorro-antd/modal';
import { DispatchRequestsNewComponent } from '../new/new.component';
import { DispatchRequestsNewMassDispatchComponent } from '../new-mass-dispatch/new-mass-dispatch.component';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-dispatch-requests-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less'],
})
export class DispatchRequestsListComponent implements OnInit {
  isLoading: boolean;
  items: any[] = [];
  filteredItems: any[] = [];
  statusSearch: string = 'New';
  filterVisible: boolean = false;
  filterFields: any[] = [
    {
      label: 'Type',
      key: 'type',
      searchValue: '',
    },
    {
      label: 'Warehouse',
      key: 'warehouse.name',
      searchValue: '',
    },
    {
      label: 'Client',
      key: 'client.name',
      searchValue: '',
    },
    {
      label: 'Location',
      key: 'clientLocation.name',
      searchValue: '',
    },
  ];

  constructor(
    private modalService: NzModalService,
    private apollo: Apollo,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.load();
  }
  filterData(): void {
    const filterFieldsInput = this.filterFields.filter(field => field.searchValue.trim() !== '');

    let filteredItems: any[];

    if (filterFieldsInput.length > 0) {
      filteredItems = this.items.filter(item => {
        for (const field of filterFieldsInput) {
          const searchValue = field.searchValue.toLowerCase();
          const itemValue = this.getItemValue(item, field.key).toLowerCase();
          if (!itemValue.includes(searchValue)) {
            return false;
          }
        }
        return true;
      });
    } else {
      filteredItems = [...this.items];
    }

    this.filteredItems = filteredItems;

    if (filterFieldsInput.length === 0) {
      this.load();
    }
  }






  getItemValue(item: any, key: string): string {
    const keys = key.split('.');
    let value = item;
    for (const k of keys) {
      if (value && value.hasOwnProperty(k)) {
        value = value[k];
      } else {
        return '';
      }
    }
    return value;
  }

  load(): void {
    this.isLoading = true;
    let query;
    let variables;

    if (this.statusSearch === 'All') {
      query = gql`
        query GetDispatchRequests {
          dispatchRequests {
            id
            status
            createdOn
            startDateTime
            finishDateTime
            warehouse {
              name
            }
            targetWarehouse {
              name
            }
            client {
              name
            }
            clientLocation {
              name
            }
            items {
              amount
              item {
                name
                brand
              }
            }
            type
          }
        }
      `;
      variables = null;
    } else {
      query = gql`
        query GetDispatchRequests($status: String!) {
          dispatchRequests(status: $status) {
            id
            status
            createdOn
            startDateTime
            finishDateTime
            warehouse {
              name
            }
            targetWarehouse {
              name
            }
            client {
              name
            }
            clientLocation {
              name
            }
            items {
              amount
              item {
                name
                brand
              }
            }
            type
          }
        }
      `;
      variables = {
        status: this.statusSearch,
      };
    }

    this.apollo
      .use('common')
      .query({
        fetchPolicy: 'no-cache',
        query: query,
        variables: variables,
      })
      .subscribe(
        (result: any) => {
          this.items = result.data.dispatchRequests;
          this.filteredItems = this.items;
          this.isLoading = false;
        },
        (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      );
  }

  new(): void {
    const checked = this.items.filter((x) => x.isChecked && x.isChecked === true);
    if (checked.length === 0) return;

    this.isLoading = true;
    this.apollo
      .use('common')
      .mutate({
        mutation: gql`
          mutation CreateDelivery($data: CreateDeliveryCommand) {
            createDelivery(data: $data)
          }
        `,
        variables: {
          data: {
            dispatchRequestIds: checked.map((x) => x.id),
          },
        },
      })
      .subscribe(
        (result: any) => {
          this.router.navigateByUrl(`/delivery/${result.data.createDelivery}`);
          this.isLoading = false;
        },
        (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      );
  }

  newDispatchRequest(): void {
    this.modalService.create({
      nzWidth: '80%',
      nzTitle: 'New dispatch request',
      nzFooter: null,
      nzContent: DispatchRequestsNewComponent,
    });
  }

  newMassDispatchRequest(): void {
    this.modalService.create({
      nzWidth: '95%',
      nzTitle: 'New mass dispatch request',
      nzFooter: null,
      nzContent: DispatchRequestsNewMassDispatchComponent
    });
  }

  selectAll(): void {
    this.items.forEach((x) => (x.isChecked = true));
  }

  unselectAll(): void {
    this.items.forEach((x) => (x.isChecked = false));
  }

  cancelSelected(): void {
    const checked = this.items.filter((x) => x.isChecked && x.isChecked === true);
    if (checked.length === 0) return;

    this.isLoading = true;
    this.apollo
      .use('common')
      .mutate({
        mutation: gql`
          mutation CancelDispatchRequests($data: CancelDispatchRequestCommand) {
            cancelDispatchRequests(data: $data)
          }
        `,
        variables: {
          data: {
            dispatchRequestIds: checked.map((x) => x.id),
          },
        },
      })
      .subscribe(
        () => {
          this.load();
        },
        (error) => {
          console.error('Error:', error);
          this.isLoading = false;
        }
      );
  }

  toggleFilterVisible(): void {
    this.filterVisible = !this.filterVisible;
    if (!this.filterVisible) {
      this.resetFilterFields();
      this.load();
    }
  }

  resetFilterFields(): void {
    this.filterFields.forEach((field) => {
      field.searchValue = '';
    });
  }
  formatDate(dateTimeString): string {
    const date = new Date(dateTimeString);
    return format(date, 'dd/MM/yyyy');
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'AssignedToDelivery':
        return 'orange';
      case 'InTransit':
        return 'purple';
      case 'New':
        return 'blue';
      default:
        return 'default';
    }
  }
}
