import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-layout-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
})
export class SidebarComponent implements OnInit {
  roles = [];
  isCollapsed = false;
  isReverseArrow = false;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.loadRoles();
    this.authService.isLoggedIn.subscribe((r) => {
      if (r === true) this.loadRoles();
    });
    var theme = localStorage.getItem(`theme`);
    if (theme != null && theme == "dark") this.darkTheme();
  }

  loadRoles() {
    this.roles = JSON.parse(localStorage.getItem(`roles`));
    if (!this.roles) this.roles = [];
  }

  logout() {
    localStorage.clear();
    this.authService.isLoggedIn.emit(false);
    this.router.navigateByUrl('/login');
  }
  switchTheme() {
    var theme = localStorage.getItem(`theme`);
    console.log('theme', theme)
    if (theme == null || theme != "dark") this.darkTheme();
    else this.lightTheme();
  }
  darkTheme() {
    // = = = Main theme
    const style = document.createElement('link');
    style.type = 'text/css';
    style.rel = 'stylesheet';
    style.id = 'dark-theme';
    style.href = 'assets/themes/ng-zorro-antd.dark.min.css';

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    // = = = Mobile navbar
    var style2 = document.createElement('style');
    style2.type = 'text/css';
    style2.innerHTML = '.am-tabs-default-bar-tab { background-color: black; }';
    style2.id = 'dark-theme-navbar';
    head.appendChild(style2);


    localStorage.setItem(`theme`, `dark`);
  }

  lightTheme() {
    const head = document.getElementsByTagName('head')[0];
    head.removeChild(document.getElementById('dark-theme'))
    head.removeChild(document.getElementById('dark-theme-navbar'))
    localStorage.setItem(`theme`, `light`);
  }
}
