<nz-page-header (nzBack)="toList()" nzBackIcon nzTitle="Supplier: {{ item?.name }}">
</nz-page-header>

 <h3>Items</h3>
<nz-table #itemsTable nzSize="small" [nzData]="item?.items" style="min-width: 100%;" [nzScroll]="{ x: '800px' }">
  <thead>
    <tr>
      <th>Name</th>
      <th>Price</th>
      <th>
        <button nz-button nzShape="round" (click)="newPrice()">
            Add new item price
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let i of itemsTable.data">
      <td>
        <a [routerLink]="['/item', i.item.id]">{{
          i.item.name
        }}</a>
      </td>
      <td>{{ i.price }}</td>
      <td>
        <button nz-button nzShape="round" (click)="showPriceHistory(i)">
            Price history
        </button>
      </td>
    </tr>
  </tbody>
</nz-table>
