import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { GraphQlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-warehouse-remove-stock',
  templateUrl: './remove-stock.component.html',
  styleUrls: ['./remove-stock.component.less'],
})
export class WarehouseRemoveStockComponent implements OnInit {
  marks = {};
  isLoading;

  readonly nzModalData = inject(NZ_MODAL_DATA, { optional: true });

  @Input() warehouseItemId;
  @Input() currentAmount;

  form: FormGroup;

  constructor(
    private apollo: Apollo,
    private modalRef: NzModalRef,
    private graphQlService: GraphQlService,
    private messageService: NzMessageService
  ) {
    this.form = new FormGroup({
      action: new FormControl('-'),
      warehouseItemId: new FormControl(null, [Validators.required]),
      amount: new FormControl(1, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.warehouseItemId = this.nzModalData.warehouseItemId;
    this.currentAmount = this.nzModalData.currentAmount;

    if (!this.currentAmount)
      this.graphQlService
        .get(`{ warehouseItem(id:"${this.warehouseItemId}") { amount } }`)
        .subscribe((r) => {
          this.currentAmount = r.data['warehouseItem'].amount;
          this.setCurrentAmount();
        });
    else this.setCurrentAmount();

    this.form.get(`warehouseItemId`).setValue(this.warehouseItemId);

    this.form.get(`amount`).valueChanges.subscribe(r => {
      if (r > this.currentAmount) this.form.get(`amount`).setValue(this.currentAmount);
    })
  }

  setCurrentAmount() {
    this.marks[1] = '1';
    this.marks[Math.round(this.currentAmount / 2)] = `${Math.round(
      this.currentAmount / 2
    )}`;
    this.marks[this.currentAmount] = `${this.currentAmount}`;
  }

  submit() {
    if (this.form.status === 'INVALID') return;

    this.isLoading = true;
    this.apollo
      .use(`common`)
      .mutate({
        mutation: gql`
          mutation action($data: ModifyWarehouseItemStockCommand) {
            modifyWarehouseItemStock(data: $data)
          }
        `,
        variables: {
          data: this.form.value,
        },
      })
      .subscribe(() => {
        this.isLoading = false;
        this.messageService.success(`Stock has been decreased successfully.`);
        this.modalRef.close('ok');
      });
  }
}
