import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsLocationsListComponent } from './list/list.component';
import { ClientsLocationsModifyComponent } from './modify/modify.component';
import { SharedModule } from '../shared.module';
import { ClientsLocationsViewComponent } from './view/view.component';
import { DispatchRequestsModule } from '../dispatch-requests/dispatch-requests.module';
import { ReturnFormComponent } from './return-form/return-form.component';
import { CreateStockTemporaryComponent } from './create-stock-temporary/create-stock-temporary.component';

@NgModule({
  declarations: [
    ClientsLocationsListComponent,
    ClientsLocationsModifyComponent,
    ClientsLocationsViewComponent,
    ReturnFormComponent,
    CreateStockTemporaryComponent,
  ],
  imports: [CommonModule, SharedModule, DispatchRequestsModule],
  exports: [ClientsLocationsListComponent],
})
export class ClientsLocationsModule {}
